import classnames from 'classnames/bind';
import { FC, InputHTMLAttributes, ChangeEvent, FocusEvent, forwardRef } from 'react';

import { Label } from 'components/atoms';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';

import styles from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  register?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  subtext?: string;
  labelClassName?: string;
}

const cx = classnames.bind(styles);

type Ref = HTMLInputElement;

export const Input: FC<InputProps> = forwardRef<Ref, InputProps>(function Input(
  {
    id,
    label,
    onChange,
    onBlur,
    register,
    success,
    error = false,
    disabled = false,
    subtext,
    labelClassName,
    ...rest
  },
  ref
) {
  const inputClasses = cx({
    'input-error': error,
    'input-success': success,
    disabled,
  });

  return (
    <div className={`${styles['input-wrapper']} ${rest.className}`} data-testid='container__input'>
      <Label text={label} htmlFor={id} className={labelClassName} />
      <input
        id={id}
        data-testid='testIdInput'
        className={inputClasses}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        {...rest}
        {...(register && {
          ...register(id, { onChange, onBlur }),
        })}
      />
      <div className={styles.subtext}>
        {error && (
          <div className={styles['subtext-error']}>
            <WarningIcon />
            <span>Campo obligatorio</span>
          </div>
        )}
        {!error && <p className={styles.subtext__content}>{subtext}</p>}
      </div>
    </div>
  );
});
