import { IProfile, IResponseStatus, IUserStatus } from "utils/interfaces";
import { talentoApi } from "./index";

const profileExtensionApi = talentoApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProfileData: builder.mutation<IResponseStatus, IProfile>({
      query: (profileData) => ({
        url: `/security/users`,
        method: 'PUT',
        body: profileData, 
      }),
    }),
    getUserStatus: builder.query<IUserStatus, { email: string }>({
      query: ({ email }) => {
        return {
          url: `/stages/get-job-application-status?email=${email}`,
        };
      },
    }),

    extendTerm: builder.mutation<IResponseStatus, { email: string }>({
      query: ({ email }) => ({
        url: `/stages/get-notification-status/email`,
        method: 'POST',
        body: {
          email,
          platform: 'WEB',
        },
      }),
    }),
  }),
});

export const {
  useUpdateProfileDataMutation,
  useGetUserStatusQuery,
  useExtendTermMutation,
} = profileExtensionApi;