import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import './ContentNotFound.scss';

interface IProps {
  error: any;
  code?: string;
  titleKey?: string;
}

export const ContentNotFound = ({
  error,
  code,
  titleKey = "upsMaintenance",
}: IProps) => {

  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  return (
    <div className='error-boundary__container'>
      <p className='error-boundary__tittle'>{t(titleKey)}</p>
      <p className='error-boundary__text'>{error} {code}</p>
    </div>
  )
};