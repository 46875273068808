import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  AImage,
  Loader,
  AButton,
  ATextWithAtoms,
  ATextItem,
  AText,
  TextIcon,
  Back,
} from 'components/atoms';
import { FileLoading, InfoModal, ModalAlert } from 'components/molecules';
import DatePickers from 'components/molecules/DatePickers/DatePickers';
import ErrorBoundarySide from 'components/molecules/errorBoundaryDesktop/ErrorBoundarySide.component';
import InterviewInfo from 'components/molecules/InterviewInfo/InterviewInfo.component';
import { LoadingPage } from 'components/molecules/LoadingPage/LoadingPage.component';
import OnboardingModal from 'components/molecules/OnboardingModal/OnboardingModal.component';
import { FileUpload, ContainerSchedule } from 'components/organisms';
import {
  useGetActivitiesByIdStageQuery,
  useGetActivityStatusQuery,
  useChangeStatusMutation,
  useGetStagesQuery,
  useGetEnterviewDataQuery,
  useGetApplicationStatusbusinessDayATRQuery,
  useGetListCalendarQuery,
  useScheduleMeetingMutation,
} from 'services/talento';
import {
  selectinfoActivities,
  selectStages,
  selectUserInfo,
  selectVacantInfo,
  setScheduleActive,
  selectCurrentActivity,
  setActivityStatus,
  setStageCompleted,
  setStages,
  currentActivity,
  selectCalendar,
  setCalendar,
  selectOnboardingDashboard,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  calendarIcon,
  grupoIcon,
  sunriseIcon,
  sunsetIcon,
  videoIcons,
  gritIcon,
  clock,
} from 'utils/assets';
import { userEmailMarker, ROUTES } from 'utils/constants';
import {
  IAText,
  IATextItem,
  IAButton,
  IAFileUpload,
  IATextWithAtoms,
  IActivity,
  IModal,
  IStage,
} from 'utils/interfaces';

import './CurrentActivity.scss';
import { IResponseStages } from 'utils/interfaces/store/talento.interfaces';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';

import { ScheduleInterview } from '../ScheduleInterview/ScheduleInterview';

import { activityStates } from 'utils/helpers';
import {
  ContentTabs,
  ContentTypes,
  activities,
  ActivityTypes,
  Numbers,
} from 'utils/helpers/dictionaries';
import { useCancelMeetingMutation, useReprogramMeetingQuery, } from 'services/talentoAPI/stagesApi';
import { ONBOARDING_MODALS } from 'utils/constants/Onboarding/indexOnboarding';

import TeleworkingCondition from '../TeleworkingCondition/TeleworkingCondition.component';
import { useGetQuestionsTestGridQuery } from '../TestGrid/api/testGridApi';
import { TestProvider } from '../TestGrid/context/TestProvider';
import Questionnaire from '../TestGrid/Questionnaire';

import ReactDOM from 'react-dom';

import { log } from 'console';

interface Iprops {
  dataActivity: {
    idActivity: string;
    iconActivity: string;
    nameActivity: string;
  };
  disabled?: boolean;
}

export const CurrentActivity = ({
  dataActivity: { idActivity, iconActivity, nameActivity },
  disabled = false
}: Iprops) => {
  const { translations, isLoading: isLoadingTranslations } =
    useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoadingTranslations);
  const RESCHEDULETEXT = ' • Podrás reprogramar la entrevista una sola vez. \n   • Si requieres reprogramar tu entrevista debes hacerlo con al menos dos horas antes del comienzo de tu evento agendado.';

  const [activityType, setActivityType] = useState(0);
  const [skipGetSchedule, setSkipGetSchedule] = useState(false);
  const [isRecomendation, setIsRecomendation] = useState(false);
  const [isDescription, setIsDescription] = useState(false);
  const [isInterview, setIsInterview] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [isHelp, setIsHelp] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isScheduleDone, setIsScheduleDone] = useState(false);
  const { isCalendar } = useAppSelector(selectCalendar);
  const [testInProgress, setTestInProgress] = useState(false);
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    closeIcon: true,
    confirmButton: true,
    cancelButton: false,
    inputText: false,
  });
  const [isConfirmationSchedule, setisConfirmationSchedule] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [statusActivity, setStatusActivity] = useState('');
  const [idActivityState, setIdActivityState] = useState(idActivity);
  const [testWithFiles, setTestWithFiles] = useState(false);
  const [isButtonAttended, setIsButtonAttended] = useState(false);
  const [paddingForAlerts, setPaddingForAlerts] = useState(0);
  const [linkUploaded, setLinkUploaded] = useState(false);
  const [titleLinkButton, setTitleLinkButton] = useState('');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedHour, setSelectedHour] = useState<any>();
  const [activeDatePickersIndex, setActiveDatePickersIndex] = useState<
    number | null
  >();
  const [activateHourPicker, setActiveHourPicker] = useState<string | null>();
  const [eventId, setEventId] = useState<string | null>();
  const [argsSchedule, setArgsSchedule] = useState<any>();
  const [skip, setSkip] = useState(true);
  const [scheduleSuccess, setScheduleSuccess] = useState<boolean>(false);
  const [isCompletingStatus, setCompletingStatus] = useState(false);
  const { showOnboarding, step } = useAppSelector(selectOnboardingDashboard);

  const { vacant } = useAppSelector(selectVacantInfo);
  const { email } = useAppSelector(selectUserInfo);
  const { stages } = useAppSelector(selectStages);
  const { status, nameActivity: activityName } = useAppSelector(
    selectCurrentActivity
  );
  const { isScheduleActive } = useAppSelector(selectinfoActivities);

  const dispatch = useAppDispatch();

  const activityApiParameters = {
    idStage: idActivity,
    idVacant: vacant?.idVacant,
    email,
    activityName,
  };
  const calendarParameters = { idActivity, email };

  const CANTSCHEDULETOOLTIP = 'Ya no es posible reprogramar \n el espacio';

  const {
    data,
    isLoading,
    isError,
    refetch: getActivity,
  } = useGetActivitiesByIdStageQuery(activityApiParameters, {
    refetchOnMountOrArgChange: true,
  });
  const { data: activityStatus, refetch: getStatus } =
    useGetActivityStatusQuery(activityApiParameters);
  const [scheduleMeeting, { isLoading: isLoadingMeeting }] =
    useScheduleMeetingMutation();
  const { data: interviewInfo } = useGetEnterviewDataQuery(
    { email, idActivity },
    { skip }
  );

  const { data: canReprogramMeeting, refetch: reprogramMeeting } =
    useReprogramMeetingQuery(calendarParameters);

  const { data: calendars, refetch: getCalendar } =
    idActivity === activities.psychological_interview ||
      idActivity === activities.technical_interview
      ? useGetListCalendarQuery(calendarParameters)
      : { data: null, refetch: () => { } };
  const date = calendars?.data;

  const { data: stagesData = {} as IResponseStages, refetch: getStages } =
    useGetStagesQuery({ email });
  const [changeStatus, { }] = useChangeStatusMutation();
  const [error, setError] = useState(false);
  const { data: dataBusiness } = useGetApplicationStatusbusinessDayATRQuery({
    email,
  });
  const businessDayATR = dataBusiness?.data?.businessDayATR;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isQuestionnaireVisible, setIsQuestionnaireVisible] = useState(false);
  const [isCloseConfirmVisible, setIsCloseConfirmVisible] = useState(false);
  const [isTimeUpModalVisible, setIsTimeUpModalVisible] = useState(false);
  const [isTestFinished, setIsTestFinished] = useState(false);

  const toggleQuestionnaire = () => {
    setIsQuestionnaireVisible(!isQuestionnaireVisible);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    toggleQuestionnaire();
    navigate(ROUTES.DASHBOARD_ROUTES.HOME.TEST_GRID.ROUTE);
  };

  const handleTimeUp = () => {
    alert('Se acabó el tiempo!');
  };

  const selectTest = () => `Test ${nameActivity.replace('Test ', '')}`;

  const handleActiviyStatus = () => {
    setModal({ ...modal, isOpen: true });
    getStatus();
    getStages();
    setTestInProgress(true);
  };

  const validateActivePicker = (index: number) => {
    if (activeDatePickersIndex == index) {
      // Aquí puedes agregar lógica adicional si es necesario
      setActiveDatePickersIndex(null);
    } else {
      if (activeDatePickersIndex !== null) {
        setActiveDatePickersIndex(null);
      }
      setActiveDatePickersIndex(index);
    }
  };

  const handleScheduleSuccess = () => {
    setScheduleSuccess(true);
  };

  const searchHoursByDay = (selectedDate: string) => {
    const selectedDateInfo = date.events.find(
      (event: any) => event.date === selectedDate
    );
    if (selectedDateInfo) {
      const hours = selectedDateInfo.hours;
      setSelectedHour(hours);
    }
  };

  const handleDayClick = (selectedDate: string, index: number) => {
    setSelectedDay(selectedDate);
    validateActivePicker(index);
    // Busca las horas disponibles para el día seleccionado
    searchHoursByDay(selectedDate);
  };

  const handleHourClick = (eventId: string) => {
    if (activateHourPicker == eventId) {
      setActiveHourPicker(null);
      setEventId(null);
    } else {
      if (activateHourPicker !== null) {
        setActiveHourPicker(null);
        setEventId(null);
      }
      setActiveHourPicker(eventId);
    }
    setEventId(eventId);
  };

  const handleChangeStatus = () => {
    statusActivity === '2' &&
      changeStatus({ ...activityApiParameters, status: '3' }).then(() => {
        getStatus();
        getActivity();
        getStages().then(({ data }) => {
          data &&
            dispatch(
              setStages({
                stagesList: data.data.stages,
                isLoadingStages: false,
              })
            );
        });
      });
  };

  const handleModalAndAdvanceStage = () => {
    if (testWithFiles) {
      statusActivity === '2' &&
        changeStatus({ ...activityApiParameters, status: '3' }).then((_) => {
          getStatus();
          getActivity();
        });
    }
    setModal((state) => ({ ...state, isOpen: false }));
    statusActivity === '4' && dispatch(setStageCompleted({ id: idActivity }));
  };

  const handleCompleteStatus = () => {
    statusActivity === activityStates.inProgress.toString() &&
      changeStatus({
        ...activityApiParameters,
        status: activityStates.completed.toString(),
      }).then(() => {
        getStatus();
        getActivity();
        getStages().then(({ data }) => {
          data &&
            dispatch(
              setStages({
                stagesList: data.data.stages,
                isLoadingStages: false,
              })
            );
          setCompletingStatus(false);
        });
      });
  };

  function formatoMesYAnio(fecha: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
    };
    const fechaDate = new Date(fecha);

    const resultado = fechaDate.toLocaleDateString('es-ES', options);

    return resultado;
  }

  const detectAlerts = () => {
    const elementsInfo = document.getElementsByClassName('textIcon--info');
    const info = elementsInfo[0] as HTMLElement;
    const elementsWarning =
      document.getElementsByClassName('textIcon--warning');
    const warning = elementsWarning[0] as HTMLElement;

    if (info) {
      setPaddingForAlerts((info.offsetHeight + 20) / 10);
    } else if (warning) {
      setPaddingForAlerts((warning.offsetHeight + 20) / 10);
    }
  };

  const updateDimensions = () => {
    setIsMobile(window.innerWidth < 1024);
  };

  useEffect(() => {
    detectAlerts();
  });

  useEffect(() => {
    // if (statusActivity == '2') {
    //   getCalendar();
    // }
  }, [statusActivity]);

  useEffect(() => {
    if (scheduleSuccess) {
      setSkip(false);
      setModal({
        message: 'holaa',
        type: 'normal',
        isOpen: true,
        closeIcon: false,
        confirmButton: true,
      });
    }
  }, [scheduleSuccess]);

  useEffect(() => {
    activityStatus?.status === 200
      ? activityStatus?.data === '3'
        ? setModal({
          ...modal,
          message:
            'El sistema esta procesando tus respuestas, intenta de nuevo en unos minutos',
          type: 'inProgress',
          closeIcon: false,
          confirmButton: true,
        })
        : activityStatus?.data === '4' && !isButtonAttended
          ? setModal({
            ...modal,
            message: `Ya tenemos las respuestas de tu ${selectTest()}`,
            type: 'success',
            closeIcon: false,
            confirmButton: true,
          })
          : activityStatus?.data === '1' &&
          setModal({
            ...modal,
            message: 'Error al conectar con el servidor.',
            type: 'error',
            closeIcon: true,
            confirmButton: true,
          })
      : setModal({
        ...modal,
        message: 'Error al conectar con el servidor.',
        type: 'error',
        closeIcon: true,
        confirmButton: false,
      });
    dispatch(setActivityStatus({ status: activityStatus?.data }));
  }, [activityStatus]);

  useEffect(() => {
    if (!stagesData.data) {
      setError(true);
    }
  }, [stagesData.data]);

  useEffect(() => {
    if (!calendars) {
      setModal({
        ...modal,
        message:
          'El sistema esta procesando tus respuestas, intenta de nuevo en unos minutos',
        type: 'inProgress',
        closeIcon: false,
        confirmButton: true,
      });
    } else {
      setActiveDatePickersIndex(0);
      const argsSchedule = {
        idCalendar: calendars?.data.calendarId,
        email,
        idActivity,
        date,
        scheduleSuccess: handleScheduleSuccess,
      };
      setArgsSchedule(argsSchedule);

    }
  }, [calendars]);

  const cancelMeetPayload = {
    idEvent: eventId,
    idCalendar: calendars?.data.calendarId,
    invitedEmail: email,
    idActivity,
    candidateFullName: vacant?.fullName
  }

  const [cancelMutation, { isLoading: loaderCancel, isSuccess: cancelSuccess }] = useCancelMeetingMutation();

  const location = useLocation();

  useEffect(() => {
    if (cancelSuccess) {
      setIsModalOpen(false);
      window.location.reload();
    }
  }, [cancelSuccess])

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const [type] = stages.filter(({ id }) => id === Number(idActivity));
    setActivityType(type?.activityType);
    setIsRecomendation(false);
    setIsDescription(false);
    setIsInterview(false);
    setIsScheduleDone(false);

    const InitialSchedule =
      stages.find((stage: IStage) => stage.id === 5)?.status === '3';

    let recomndationsTmp = 0;
    let descriptionTmp = 0;
    let interviewTmp = 0;
    let detailsTmp = 0;
    let helpTmp = 0;

    data?.data.forEach((item: IActivity) => {
      item.tab === 'recomendations' && recomndationsTmp++;
      item.tab === 'description' && descriptionTmp++;
      item.tab === 'interview' && interviewTmp++;
      item.tab === 'details' && detailsTmp++;
      item.tab === 'detailsConfirm' && detailsTmp++;
      item.tab === 'help' && helpTmp++;
    });

    if (recomndationsTmp > 0) {
      setIsRecomendation(true);
    } else {
      setIsRecomendation(false);
    }
    if (descriptionTmp > 0) {
      setIsDescription(true);
    } else {
      setIsDescription(false);
    }
    if (detailsTmp > 0) {
      setIsDetails(true);
    } else {
      setIsDetails(false);
    }
    if (helpTmp > 0) {
      setIsHelp(true);
    } else {
      setIsHelp(false);
    }
    if (interviewTmp > 0) {
      setIsInterview(true);
      setIsScheduleDone(true);
    } else {
      setIsInterview(false);
      setIsScheduleDone(false);
    }

    setisConfirmationSchedule(InitialSchedule);

    setIsAlert(
      !!data?.data.find((item) => {
        if (item.type === 'textItem') {
          const action = JSON.parse(item.action);
          return action.item === 'info' || action.item === 'warning';
        }
      })
    );
  }, [data]);

  useEffect(() => {
    if (status) {
      setStatusActivity(status);
    }
    if (idActivityState !== idActivity) {
      setIdActivityState(idActivity);
    }
  }, [status, idActivity]);

  useEffect(() => {
    data?.data.map((item: IActivity) => {
      const actionObject = JSON.parse(item.action);
      if (item.type === 'linkButton') {
        const { text } = actionObject;
        setTitleLinkButton(text);
        setLinkUploaded(true);
      }
    });
  });

  // useEffect(() => {
  //   if (location.pathname === ROUTES.DASHBOARD_ROUTES.HOME.TEST_GRID.ROUTE) {
  //     setTimeout(() => setIsModalOpen(true), 100); 
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (location.state?.openModal) {
      setIsModalOpen(true);
    }
  }, [location.state]);

  const { data: fetchedQuestions } = useGetQuestionsTestGridQuery({ email });
  const questions = fetchedQuestions?.data?.map(q => ({
    testName: q.testName,
    question: q.question,
    answers: q.answers.map(a => ({
      answer: a.answer,
      weight: a.weight
    }))
  })) ?? [];

  const isMorning = (hourStr: string) => {
    return hourStr.toLowerCase().includes('am');
  };

  const morningHours = selectedHour?.filter((hour: any) =>
    isMorning(hour.startHour)
  );
  const afternoonHours = selectedHour?.filter(
    (hour: any) => !isMorning(hour.startHour)
  );
  return (
    <>
      {loaderCancel && (<LoadingPage fullScreen={true} />)}
      <ModalAlert
        width={490}
        height='45rem'
        alertType={modal.type as any}
        alertText={modal.message}
        alertTitle={modal.title ? modal.title : undefined}
        isOpened={modal.isOpen}
        closeIcon={modal.closeIcon}
        confirmButton={modal.confirmButton}
        cancelButton={modal.cancelButton}
        cancelFunction={() =>
          setModal((state) => ({ ...state, isOpen: false }))
        }
        toggleModal={handleModalAndAdvanceStage}
      >
        {modal.type === 'normal' && (
          <div className="modalAlert">
            <h1 className="currentActivity__title whiteText">
              Confirmación entrevista
            </h1>
            <div className="modal">
              <div className="modalContainer">
                <AImage
                  alt="calendarIcon"
                  url={calendarIcon}
                  className="whiteIcon"
                />
                <div className="currentActivity__details-paragraph modalcontainer__text">
                  <p className="currentActivity__details-paragraph whiteText">
                    {interviewInfo?.data.date_interviewer.slice(0, -7)}
                  </p>
                  <p className="currentActivity__details-paragraph whiteText">
                    {interviewInfo?.data.range_hours}
                  </p>
                </div>
              </div>
              <div className="modalContainer mt-3">
                <AImage
                  alt="calendarIcon"
                  url={videoIcons}
                  className="whiteIcon"
                />
                <p className="currentActivity__details-paragraph whiteText">
                  Google Meet
                </p>
              </div>
              <div className="modalContainer mt-3">
                <AImage
                  alt="calendarIcon"
                  url={grupoIcon}
                  className="whiteIcon"
                />
                <div className="modalContainer__text">
                  <p className="currentActivity__details-paragraph whiteText">
                    Invitados
                  </p>
                  <p className="currentActivity__details-paragraph whiteText">
                    {interviewInfo?.data.name_interviewer} - Profesional TH
                  </p>
                  <p className="currentActivity__details-paragraph whiteText">
                    {vacant?.fullName} - Candidato
                  </p>
                </div>
              </div>
            </div>
            <AButton
              dataTestId="confirm-button"
              tag="button"
              handleClick={() => {
                window.location.reload();
              }}
              className="button-primary modal-alert__button mt-3 centerButton"
            >
              {' '}
              Ok{' '}
            </AButton>
          </div>
        )}
      </ModalAlert>

      {isLoading && calendars ? (
        <div className="currentActivity__loader">
          <Loader />
        </div>
      ) : isError || error ? (
        <div className="currentActivity__error">
          <ErrorBoundarySide code={stagesData.errorCode} />
        </div>
      ) : (
        <article
          className={`${isLoadingMeeting ? 'currentActivity__loader2' : 'currentActivity'} ${step === 5 ? "currentActivity--show" : ""}`}
          style={
            isAlert
              ? {
                paddingBottom: `${paddingForAlerts}rem`,
              }
              : {
                paddingBottom: '2.9rem',
              }
          }
        >
          {disabled && <div className="currentActivity__activities-lock" />}
          {!isCalendar ? (
            <div>
              <div className="currentActivity__header">
                {!isScheduleDone ? (
                  <h2 className="currentActivity__title">{nameActivity}</h2>
                ) : (
                  <h2 className="currentActivity__title">
                    Confirmación entrevista
                  </h2>
                )}
                <AImage
                  className="currentActivity__image"
                  url={iconActivity}
                  alt={nameActivity}
                />
              </div>
              <>
                {

                  isInterview && (
                    <div className="currentActivity__interview__container">
                      {
                        <div className="currentActivity__interview">
                          <InterviewInfo
                            email={email}
                            idActivity={idActivity}
                            fullName={vacant?.fullName}
                          />
                        </div>
                      }
                    </div>
                  )
                }

                <div className="currentActivity__information">
                  {isDescription && (
                    <h3 className="currentActivity__description">
                      Descripción
                    </h3>
                  )}
                  {data?.data.map((item: IActivity) => {
                    const actionObject = JSON.parse(item.action);
                    if (item.tab === 'description') {
                      if (item.type === 'text') {
                        const { text } = actionObject as IAText;
                        return <AText key={item.id} text={text} />;
                      }
                      if (item.type === 'inputFile') {
                        const { title } = actionObject as IAFileUpload;
                        return <FileUpload key={item.id} title={title} />;
                      }
                      if (item.type === 'textItem') {
                        const { text, item: element } =
                          actionObject as IATextItem;
                        return (
                          <ATextItem key={item.id} text={text} item={element} />
                        );
                      }

                      if (item.type === 'textWithAtoms') {
                        const {
                          text,
                          links: enlaces,
                          icons,
                        } = actionObject as IATextWithAtoms;
                        return (
                          <ATextWithAtoms
                            key={item.id}
                            text={text}
                            links={enlaces}
                            icons={icons}
                          />
                        );
                      }

                      if (item.type === 'helpDescription') {
                        const { text } = actionObject as IAText;
                        return (
                          <AText
                            key={item.id}
                            text={text}
                            classStyle="text-help"
                          />
                        );
                      }
                    }
                  })}
                </div>

                <div className="currentActivity__information">
                  {isRecomendation && (
                    <h3 className="currentActivity__recommendations">
                      Recuerda
                    </h3>
                  )}

                  {data?.data.map((item: IActivity) => {
                    const actionObject = JSON.parse(item.action);

                    if (item.tab === 'recomendations') {
                      if (item.type === 'textItem') {
                        const {
                          text,
                          textBold,
                          title,
                          item: element,
                        } = actionObject as IATextItem;
                        return (
                          <ATextItem
                            key={item.id}
                            text={text}
                            item={element}
                            title={title}
                            textBold={textBold}
                          />
                        );
                      }
                      if (item.type === 'text') {
                        const { text } = actionObject as IAText;
                        return <AText key={item.id} text={text} />;
                      }
                      if (item.type === 'textWithAtoms') {
                        const {
                          text,
                          links: enlaces,
                          icons,
                          item: element,
                        } = actionObject as IATextWithAtoms;
                        return (
                          <ATextWithAtoms
                            key={item.id}
                            text={text}
                            links={enlaces}
                            icons={icons}
                            item={element}
                          />
                        );
                      }
                    }
                  })}
                </div>

                {data?.data.map((item: IActivity) => {
                  const actionObject = JSON.parse(item.action);

                  if (item.tab === 'help') {
                    if (item.type === 'textItem') {
                      const { text, item: element } =
                        actionObject as IATextItem;
                      return (
                        <ATextItem
                          key={item.id}
                          text={text}
                          item={element}
                          help
                        />
                      );
                    }
                    if (item.type === 'text') {
                      const { text } = actionObject as IATextItem;
                      return <AText key={item.id} text={text} />;
                    }
                  }
                })}

                <div className="currentActivity__information">
                  {isDetails && (
                    <h3 className="currentActivity__recommendations">
                      Detalles del encuentro
                    </h3>
                  )}
                  <div className="currentActivity__details-interview">
                    {data?.data.map((item: IActivity) => {
                      const actionObject = JSON.parse(item.action);

                      if (
                        item.tab === 'details' ||
                        item.tab === 'detailsConfirm'
                      ) {
                        if (item.type === 'textItem') {
                          const { text, item: element } =
                            actionObject as IATextItem;
                          return (
                            <p
                              className="currentActivity__details-paragraph"
                              key={item.id}
                            >
                              <span className="currentActivity__details-item">
                                {element}
                              </span>
                              {text}
                            </p>
                          );
                        }
                        if (item.type === 'textWithAtoms') {
                          const {
                            text,
                            links: enlaces,
                            icons,
                            item: element,
                          } = actionObject as IATextWithAtoms;
                          return (
                            <ATextWithAtoms
                              className="currentActivity__details-paragraph"
                              key={item.id}
                              text={text}
                              links={enlaces}
                              icons={icons}
                              item={element}
                            />
                          );
                        }
                      }
                    })}
                    {isScheduleDone && (
                      <div className='pt-1'>
                        <div className="tooltip-container">
                          {!canReprogramMeeting?.canReschedule && <span className="tooltip__schedule">{CANTSCHEDULETOOLTIP}</span>}
                          <AButton
                            disabled={!canReprogramMeeting?.canReschedule}
                            className='button-secondary'
                            type='button'
                            handleClick={() => setIsModalOpen(true)}
                            tag='button'
                          >
                            ¿Necesitas reprogramar?
                          </AButton>
                        </div>

                        <InfoModal
                          isVisible={isModalOpen}
                          title="Reprogramar entrevista"
                          subtitle='Recuerda:'
                          text={RESCHEDULETEXT}
                          buttonAction={() => {
                            cancelMutation(calendarParameters);
                          }}
                          onClose={() => setIsModalOpen(false)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="currentActivity__buttons">
                  {data?.data.map((item: IActivity) => {
                    const actionObject = JSON.parse(item.action);
                    if (item.tab === ContentTabs.both) {
                      if (item.type === ContentTypes.button) {
                        const { title, url } = actionObject as IAButton;
                        if (
                          activityType !== ActivityTypes.interview &&
                          !disabled
                        ) {
                          const url1 = parseInt(idActivity) !== 3 ? url?.replace(userEmailMarker, email) : undefined;

                          // Modal del test grit
                          const handleButtonClick = () => {
                            if (parseInt(idActivity) == 3) {
                              navigate(ROUTES.DASHBOARD_ROUTES.HOME.TEST_GRID.ROUTE);
                            } else {
                              handleChangeStatus();
                            }
                          };

                          const handleModalConfirm = () => {
                            setIsModalOpen(false);
                            handleChangeStatus();
                            navigate(ROUTES.DASHBOARD_ROUTES.HOME.TEST_GRID.ROUTE);
                          };

                          return (
                            <>
                              <AButton
                                tag="button"
                                key={item.id}
                                disabled={disabled}
                                className={`${statusActivity === activityStates.initial.toString()
                                  ? 'button-primary--dark'
                                  : activityType === 2
                                    ? "button__test"
                                    : 'button-secondary'} 
                                btn__desktop-edit`}
                                handleClick={() => {
                                  if (parseInt(idActivity) !== 3) {
                                    handleChangeStatus();
                                    if (url1) window.open(url1, '_blank');
                                  } else {
                                    handleButtonClick();
                                  }
                                }}
                              >
                                {"¡Tomar test!"}
                              </AButton>

                              {parseInt(idActivity) === 3 && isQuestionnaireVisible && (
                                <TestProvider>
                                  <Questionnaire
                                    email={email}
                                    questions={questions}
                                    onClose={() => setIsQuestionnaireVisible(false)}
                                    onFinish={() => {
                                      setIsQuestionnaireVisible(false);
                                    }}
                                    timer={undefined}
                                    onTimeUp={undefined}
                                  />
                                </TestProvider>
                              )}
                            </>
                          );
                        } else if (!disabled) {
                          return (
                            <div key={item.id}>
                              <AButton
                                tag="a"
                                key={item.id}
                                disabled={disabled}
                                className={`${statusActivity === '2'
                                  ? 'button-primary--dark'
                                  : 'button-secondary'
                                  }`}
                                url={
                                  parseInt(idActivity) == 5 ||
                                    parseInt(idActivity) == 45
                                    ? undefined
                                    : stages.find(
                                      (item) =>
                                        item.id === parseInt(idActivity)
                                    )?.url
                                }
                                target={'_blank'}
                                handleClick={() => {
                                  if (
                                    parseInt(idActivity) == 5 ||
                                    parseInt(idActivity) == 45
                                  ) {
                                    dispatch(setCalendar({ isCalendar: true }));
                                  } else {
                                    changeStatus({
                                      ...activityApiParameters,
                                      status: '2',
                                    });
                                  }
                                }}
                              >
                                {/* botón ver agenda */}
                                {title}
                              </AButton>
                            </div>
                          );
                        } else {
                          return (
                            <AButton
                              tag="a"
                              key={item.id}
                              disabled={disabled}
                              className={`${statusActivity === '2'
                                ? 'button-primary--dark'
                                : 'button-secondary'
                                }`}
                              handleClick={() => { }}
                            >
                              {title}
                            </AButton>
                          );
                        }
                      }

                      if (item.type === 'buttonAttended') {
                        const { title } = actionObject as IAButton;
                        return (
                          <AButton
                            tag="button"
                            key={item.id}
                            disabled={disabled}
                            className={`${statusActivity === '2'
                              ? 'button-primary--dark'
                              : 'button-secondary'
                              } btn__desktop-edit `}
                            target={'_blank'}
                            handleClick={() => {
                              setModal({
                                message: t('messageModalAttended'),
                                type: 'success',
                                isOpen: true,
                                closeIcon: false,
                                confirmButton: true,
                              });
                              changeStatus({
                                ...activityApiParameters,
                                status: '4',
                              }).then((_) => {
                                getStatus();
                                getActivity();
                              });
                              setIsButtonAttended(true);
                            }}
                          >
                            {title}
                          </AButton>
                        );
                      }
                      if (item.type === 'inputFile') {
                        const { title } = actionObject as IAFileUpload;
                        return (
                          <div key={item.id} className="wrapper__fileUpload">
                            <FileUpload
                              title={title}
                              linkUploaded={linkUploaded}
                              titleLinkButton={titleLinkButton}
                            />
                          </div>
                        );
                      }

                      if (item.type === 'testWithFiles') {
                        const { title } = actionObject as IAFileUpload;

                        return (
                          <AButton
                            key={item.id}
                            tag="button"
                            className="button-primary--dark testWithFiles__button"
                            handleClick={() => {
                              setModal({
                                message: `Recuerda que una vez empieces la prueba  tendrás ${businessDayATR || '3'
                                  } día${businessDayATR > 1 ? "s" : ""} para finalizarla y enviarla por medio de esta plataforma`,
                                isOpen: true,
                                confirmButton: true,
                                type: 'question',
                                closeIcon: false,
                                cancelButton: true,
                              });
                              setTestWithFiles(true);
                            }}
                          >
                            {title}
                          </AButton>
                        );
                      }

                      if (item.type === 'textItem') {
                        const {
                          text,
                          textBold,
                          title,
                          item: element,
                          link,
                        } = actionObject as IATextItem;
                        return (
                          <ATextItem
                            key={item.id}
                            text={text}
                            item={element}
                            title={title}
                            textBold={textBold}
                            link={link}
                          />
                        );
                      }
                    }
                  })}

                  {activityType === 2 && parseInt(idActivity) !== 3 && (
                    <AButton
                      tag="button"
                      disabled={
                        statusActivity === activityStates.initial.toString() ||
                        disabled ||
                        isCompletingStatus
                      }
                      className={`${statusActivity ===
                        activityStates.inProgress.toString() ||
                        statusActivity === activityStates.completed.toString()
                        ? 'button-primary--dark'
                        : 'button-secondary'
                        } currentActivity__buttons__completed-test`}
                      handleClick={() => {
                        (statusActivity ===
                          activityStates.inProgress.toString() ||
                          statusActivity ===
                          activityStates.completed.toString()) &&
                          !disabled &&
                          handleActiviyStatus();
                      }} //
                    >
                      ¡Ya completé mi test!
                    </AButton>
                  )}
                </div>
              </>
            </div>
          ) : isLoadingMeeting ? (
            <Loader className="loader__purple" full={true} />
          ) : (
            <ScheduleInterview props={argsSchedule}></ScheduleInterview>
          )}
          {showOnboarding && step === 5 && (
            <OnboardingModal
              title={ONBOARDING_MODALS[5].TITLE}
              text={ONBOARDING_MODALS[5].TEXT}
              step={Numbers.five}
              className={ONBOARDING_MODALS[5].CLASSNAME}
              trianglePosition={ONBOARDING_MODALS[5].TRIANGLE_POSITION}
              email={email}
            />
          )}
        </article>
      )}currentActivity__title
    </>
  );
};
