import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { GreenApi } from "utils/helpers/dictionaries";

export const greenApi = createApi({
    reducerPath: GreenApi.greenApi,
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SAP_URL
    }),
    endpoints: (builder) => ({
        getGreenList: builder.query({
            query: () => ({
                url: 'greenList'
            })
        }),
        createGreenList: builder.mutation<any, { userId: string }>({
            query: ({ userId }) => ({
                url: 'greenList',
                method: 'POST',
                body: { userId }
            })
        }),
        deleteGreenList: builder.mutation<any, { userId: string }>({
            query: ({ userId }) => ({
                url: 'greenList',
                method: 'DELETE',
                body: { userId }
            })
        })
    })
})

export const {
    useGetGreenListQuery,
    useCreateGreenListMutation,
    useDeleteGreenListMutation
} = greenApi