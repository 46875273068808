import { Amplify } from 'aws-amplify';
import amplifyConfig from 'aws-exports';
import { selectLanguageCode } from 'store/translation/translation.slice';
import { useAppSelector } from 'store/hooks';
import { useGetTextInSelectLanguaje } from 'hooks/useGetTextInSelectLanguaje';
import { AppRoutes } from 'routes/AppRoutes';
import { datadogRum } from '@datadog/browser-rum';
amplifyConfig.oauth.redirectSignIn = `${window.location.origin}/admin`;
amplifyConfig.oauth.redirectSignOut = `${window.location.origin}/admin`;
Amplify.configure(amplifyConfig);

datadogRum.init({
  applicationId: '2341bf0e-23dc-47b4-b7d6-f40304a1a865',
  clientToken: 'pubea5ba66fdab3e37b2930e1b0f4f418aa',
  // site refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'pragma-rum-plataforma-de-ingreso-pdn',
  env: 'pdn',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  defaultPrivacyLevel: 'allow',
});

const App = () => {
  const languageCode = useAppSelector(selectLanguageCode);
  const { isError } = useGetTextInSelectLanguaje(languageCode);

  return (
    <div className="app">
      <AppRoutes />
    </div>
  );
};

export default App;
