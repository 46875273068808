import { DropdownIndicatorProps, GroupBase, components } from 'react-select';
import {chevronUp, chevronUpErrorr} from 'utils/assets'; // Asegúrate de tener esta imagen en tu proyecto

const CustomDropdownIndicator = (props:any) => {
  const {error} = props;
  return (
    <components.DropdownIndicator {...props}>
      {error ? (
        <img src={chevronUpErrorr} alt="error" style={{ width: '24px', height: '24px', marginRight:2 }} />
      ) : (
      <img src={chevronUp} alt="dropdown icon" style={{ width: '24px', height: '24px', marginRight:2 }} />
      )}
    </components.DropdownIndicator>
  );
};

export default CustomDropdownIndicator;
