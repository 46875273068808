import { Loader } from 'components/atoms';
import './ContainerLoader.scss';

export const ContainerLoader = () => {
  return (
    <div className='container-loader' data-testid='loader'>
      <Loader className='loader__purple' />
    </div>
  );
};
