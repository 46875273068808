import * as yup from 'yup';

export const getProfileFormSchema = (hasGritInformation: boolean) => {
    let schema = yup.object().shape({
        email: yup
            .string()
            .trim()
            .email()
            .required(),
        phoneNumber: yup
            .string()
            .trim()
            .matches(/([\+]([0-9]|[\(|\)|\s]){10,17}$)|(^[0-9]{9,13}$)/)
            .required(),
        country: yup
            .number()
            .required(),
        department: yup
            .number()
            .min(1)
            .required(),
        city: yup
            .number()
            .required(),
        direction: yup
            .string()
            .trim()
            .required(),
        numberId: yup
            .string()
            .trim()
            .required(),
        idType: yup
            .string()
            .trim()
            .required(),
        bornDate: yup
            .string()
            .trim()
            .required()   
    });

    // if (hasGritInformation) {
    //     schema = schema.shape({
    //         bloodType: yup
    //             .string()
    //             .trim()
    //             .required(),
    //     });
    // }

    return schema
}