import { forwardRef, useEffect, useState } from 'react';
import ReactSelect, { GroupBase, StylesConfig } from 'react-select';

import { AImage, Label } from 'components/atoms';
import { IOption } from 'utils/interfaces';
import './Select.scss';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';

import CustomDropdownIndicator from './CustomDropdownIndicator.component';
export interface IProps {
  textLabel?: string;
  htmlForLabel?: string;
  disabled?: boolean;
  options?: Array<IOption>;
  handleChange: (value?: number | string) => void;
  selected?: string | number;
  placeholder?: string;
  error?: boolean;
  indicative?: boolean;
  value?: IOption;
  className?: string;
  onBlur?: () => void;
  subtext?: string;
  onClick?: () => void;
}

const generateCustomStyles = (
  errorStatus: boolean
): StylesConfig<IOption, false, GroupBase<IOption>> => {
  return {
    container: (provided) => ({
      ...provided,
      height: 'max-content',
      color: '#fff',
    }),
    input: (provided) => ({
      ...provided,
      color: '#330072',
      opacity: '0.4',
    }),
    menuList: (provided) => ({
      ...provided,
      height: 'auto',
      background: '#1F0D3F',
      borderRadius: '15px',
      oveflow: 'hidden',
    }),
    control: (provided, state) => ({
      ...provided,
      background: errorStatus ? 'rgba(237, 0, 57, 0.08)' : 'rgba(100, 41, 205, 0.08)',
      opacity: 1,
      minHeight: 'auto',
      borderColor: errorStatus ? '#B8002C' : '#6429CD',
      borderRadius: '100px',
      boxShadow: errorStatus ? '0px 0px 10px 0px #ED00394D inset' : '',
      svg: {
        backgroundColor: errorStatus ? '#660019' : '#6429CD',
      },
      '&:hover': {
        borderColor: errorStatus ? '#660019' : '#6429CD',
        boxShadow: errorStatus ? '' : '0px 0px 15px #6429CD',
      },
      '&:focus': {
        borderColor: errorStatus ? '#660019' : '#330072',
        outline: 'none',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      height: 'auto',
      width: '100%',
      borderRadius: '15px',
      padding: '4px 10px',
      background: '#1F0D3F',
      boxShadow: '0px 0px 15px #6429CD',
      overflow: 'hidden',
      position: 'absolute',
      zIndex: '20',
    }),
    option: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
      padding: '2px 0',
      '.label-li': {
        display: 'inline',
        width: 'max-content',
        color: '#330072',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6429CD',
      opacity: 0.4,
    }),
  };
};

export const Select = forwardRef<any, IProps>(function Select(
  {
    textLabel,
    htmlForLabel,
    disabled,
    options = [],
    handleChange,
    selected,
    placeholder,
    error = false,
    indicative,
    value,
    className,
    onBlur,
    subtext = '',
    onClick,
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption>();

  useEffect(() => {
    if (!selected && !value) return;
    const findSelectedOption = options.find(
      (option) => option.value === selected || option.value === value?.value
    );
    setSelectedOption(findSelectedOption);
  }, [selected, options, value]);

  return (
    <div className={`container-select ${className}`}>
      {textLabel && htmlForLabel && <Label text={textLabel} htmlFor={htmlForLabel} />}

      <ReactSelect
        name={htmlForLabel}
        data-testid='testIdSelect'
        value={selectedOption /* ? selectedOption : { label: '', value: '' } */}
        className={`container-select__input ${!selectedOption && 'placeholder'}`}
        onMenuOpen={() => {
          setOpen(true);
          onClick && onClick();
        }}
        onMenuClose={() => setOpen(false)}
        menuIsOpen={open}
        isClearable={true}
        options={options}
        onBlur={onBlur}
        ref={ref}
        components={{
          DropdownIndicator: (props) => (
            <CustomDropdownIndicator
              className={`custom-dropdown-indicator ${open ? 'open' : ''}`}
              {...props}
              error={error}
            />
          ),
        }}
        formatOptionLabel={(opt) => {
          return (
            <div className='container-select__options'>
              {/* La bandera en el select se comenta porvisionalmente mientras que el api de bitacora soporte las imagenes de banderas
              <AImage
                url={typeof opt !== 'string' && indicative ? opt.iconUrl : ''}
                alt="bandera"
                className="icon-li"
              /> */}

              <p className='label-li'>{`${
                typeof opt !== 'string' ? (indicative ? '+' + opt.label : opt.label) : opt
              }`}</p>
            </div>
          );
        }}
        placeholder={placeholder ?? 'Seleccione uno'}
        menuPlacement='auto'
        theme={(theme) => ({
          ...theme,
          borderRadius: 7,
          colors: {
            ...theme.colors,
            primary: '#6f2fd3',
            primary25: '#6429CD',
          },
        })}
        styles={generateCustomStyles(error)}
        isDisabled={disabled}
        onChange={(currentSelection) => {
          setSelectedOption(currentSelection || undefined);
          handleChange(currentSelection?.value);
        }}
        inputId={htmlForLabel}
      />
      <div className='subtext'>
        {error && (
          <div className={`${error ? 'subtext-error' : 'input-subtext'}`}>
            <WarningIcon />
            <span>Campo obligatorio</span>
          </div>
        )}
      </div>
    </div>
  );
});
