import { Auth } from 'aws-amplify';
import { logout as logoutCleanState, selectOnboardingDashboard, selectShowHelpModal, selectShowProfileModal } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { TabbarItem, AImage, AButton } from 'components/atoms';
import { userIcon, logoutIcon, strokeLine, backIcon, strokeLineSmall } from 'utils/assets';
import './Tabbar.scss';
import { useWindowMobile } from 'hooks/useWindowMobile';
import { TabbarItemPath, tabbarItems } from 'utils/constants';
import React, { useEffect, useState } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import InfoTooltip from '../InfoTooltip/InfoTooltip.component';
import { ONBOARDING_MODALS } from 'utils/constants/Onboarding/indexOnboarding';

interface IProps {
  hiddenItems?: TabbarItemPath[];
  isMenuOpen: (isActive: boolean) => void;
}

export const Tabbar = ({ hiddenItems, isMenuOpen }: IProps) => {
  const [menuState, setMenuState] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { isMobile } = useWindowMobile(1279);
  const showPrfModal = useAppSelector(selectShowProfileModal);
  const showHelpModal = useAppSelector(selectShowHelpModal);
  const { pathname } = useLocation();

  const handleClick = async () => {
    await Auth.signOut();
    dispatch(logoutCleanState());
  };

  const { isActive } = useAppSelector((state) => state.user.user);

  const toggleMenu = () => {
    setMenuState(prevState => {
      const newState = !prevState;
      return newState;
    });
  };

  useEffect(() => {
    isMenuOpen(menuState);
  }, [menuState])


  const activeRoutes = tabbarItems.map(({ path }) => useMatch(path));

  return (
    <>
      {!isMobile && (
        <nav className="tabbar">
          <ul className="tabbar__list">
            {pathname === "/profile" && showPrfModal && (
              <InfoTooltip
                title={ONBOARDING_MODALS[7].TITLE}
                text={ONBOARDING_MODALS[7].TEXT}
              />
            )}
            {pathname === "/help" && showHelpModal && (
              <InfoTooltip
                title={ONBOARDING_MODALS[8].TITLE}
                text={ONBOARDING_MODALS[8].TEXT}
                className={ONBOARDING_MODALS[8].CLASSNAME}
              />
            )}
            {tabbarItems.map(({ title, path, icon, isDisabled }, index) => {
              if (hiddenItems && hiddenItems.includes(path))
                return <React.Fragment key={path}></React.Fragment>;
              return (
                <li key={path} className={activeRoutes[index] ? 'tabbar__item__active' : 'tabbar__item__inactive'}>
                  <TabbarItem
                    path={path}
                    title={title}
                    icon={icon}
                    enableText={menuState}
                    isDisabled={
                      !isActive && path === tabbarItems[2].path
                        ? true
                        : isDisabled
                    }
                  />
                  {!menuState && <span className="tooltip-text">{title}</span>}
                </li>
              );
            })}
          </ul>

          <div className='menu--transform' onClick={toggleMenu}>
            <AImage className={!menuState ? "tabbar__imageLogout__expandIcon" : "tabbar__imageLogout"} url={backIcon} alt="" />
          </div>

          <div className="tabbar__logout">

            <div className="tabbar__logoutBox">
              <AImage className="tabbar__imageLogout" url={menuState ? strokeLine : strokeLineSmall} alt="" />
              {menuState && <AButton
                dataTestId="logout-button"
                tag="button"
                className="tabbar__buttonLogout"
              >
                © 2024 - Power by Pragma
              </AButton>}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
