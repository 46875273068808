import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  selectUi,
  setShowPassword,
  setUserInfo,
  resetLoginForm,
  resetLoginFormFull,
  selectLoginForm,
  setLoginForm,
  setRestorePasswordForm,
  setFormType,
  selectUserInfo,
} from 'store';
import { selectTranslations } from 'store/translation/translation.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useTranslation } from 'hooks/useTranslation';
import { ILoginForm, IModal } from 'utils/interfaces';
import { loginFormSchema } from 'utils/schemas';
import {
  errorIconParagraph,
  passwordHideIcon,
  passwordShowIcon,
  warningIcon,
} from 'utils/assets';
import { LoadingPage } from 'components/molecules/LoadingPage/LoadingPage.component';
import { ConfirmNewPasswordPage } from 'pages/Authentication';
import Lottie from 'lottie-react-web';
import { Seo } from 'components/organisms';
import { LogoPragma, AImage, AButton } from 'components/atoms';
import { AnimationLogo } from 'utils/assets';
import { logoGoogle } from 'utils/assets';
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import './LoginPanel.scss';
import { ModalAlert } from 'components/molecules';
import {
  EmptyString,
  LoginPanelConst,
  Numbers,
} from 'utils/helpers/dictionaries';
import { string } from 'yup';
interface ICurrentUser {
  cognitoUser?: [];
  requiredAttributes?: [];
}

export const LoginPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const { email, password } = useAppSelector(selectLoginForm);
  const { showPasswordLogin } = useAppSelector(selectUi);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [needNewPassword, setNeedNewPassword] = useState(false);
  const [currentUser, setCurrentUser] = useState<ICurrentUser>();
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({
    mode: 'onChange',
    defaultValues: { email, password },
    resolver: yupResolver(loginFormSchema),
  });
  const applicationId = process.env.REACT_APP_APPLICATION_ID || '';
  const securityRoles = process.env.REACT_APP_SECURITY_ROLES;
  const token = process.env.REACT_APP_API_KEY;

  const [authenticated, setAuthenticated] = useState(false);
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    handleClick: () => { },
    confirmButton: false,
    title: '',
  });

  useEffect(() => {
    const animation = document.getElementById('animation');
    const logo = document.getElementById('logo');
    setTimeout(() => {
      animation?.classList.remove('login-page__animation');
      animation?.classList.add('login-page__animation--hidden');
    }, 3200);
    setTimeout(() => {
      logo?.classList.remove('login-page__logo-pragma');
      logo?.classList.add('login-page__logo-pragma--show');
    }, 3200);

    // Verificar si el usuario está autenticado después de redirigir desde Google
    const checkAuthenticated = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser({ cognitoUser: user, requiredAttributes: [] });
        setAuthenticated(true);
      } catch (error) {
        setAuthenticated(false);
      }
    };

    checkAuthenticated();
  }, []); // Solo ejecutar una vez al montar el componente

  useEffect(() => {
    const fetchData = async () => {
      // Realizar acciones adicionales después de la autenticación
      if (authenticated) {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const response = await callLambda(
            applicationId,
            user.attributes.email || EmptyString.emptyString
          );
          if (response?.dato && response.dato.length > 0) {
            // Redirigir al usuario al panel
            localStorage.setItem(
              LoginPanelConst.datos,
              JSON.stringify(response.dato)
            );
            const recargado = localStorage.getItem(LoginPanelConst.recargado);
            if (!recargado) {
              localStorage.setItem(
                LoginPanelConst.recargado,
                LoginPanelConst.true
              );

              window.location.reload();
            }
            navigate('/admin/nuevospragmaticos');
          } else {
            // No tiene roles, cerrar sesión y redirigir al login
            setModal({
              message: LoginPanelConst.noPermissions,
              type: LoginPanelConst.Error,
              isOpen: true,
            });
            setTimeout(async () => {
              await Auth.signOut();
            }, Numbers.timeOut); // Cerrar sesión (elimina el token y redirige al login)
          }
        } catch (error) {
          console.error(LoginPanelConst.Error, error);
        }
      }
    };

    fetchData();
  }, [authenticated]);

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    dispatch(setLoginForm({ name, value }));
  };

  const handleAccessAdmin = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.error('Error al acceder al iniciar sesión con google', error);
      // Manejar el error según tus necesidades
    }
  };

  const callLambda = async (application: string, email: string) => {
    try {
      if (token) {
        const options = {
          method: 'GET',
          headers: {
            'x-api-key': token,
          },
        };
        const rute = `${securityRoles}?application=${application}&email=${email}`;
        const response = await fetch(rute, options);
        const data = await response.json();
        return data;
      }
    } catch (error) {
      setModal({
        message: 'Ha ocurrido un error',
        isOpen: true,
        type: 'error',
        confirmButton: true,
        title: undefined,
      });
      setTimeout(async () => {
        await Auth.signOut();
      }, 4000);
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <ModalAlert
        isOpened={modal.isOpen}
        alertType={modal.type}
        alertText={modal.message}
        alertTitle={modal.title}
        confirmButton={modal.confirmButton}
        toggleModal={(isOpen: boolean) =>
          setModal((state) => ({ ...state, isOpen }))
        }
      />

      <Seo title="Login | Pragma 💜">
        <main className="login-page" data-testid="main">
          <div className="login-page__wrapper">
            <div className="login-page__authenticate">
              <div className="login-page__info-pragma">
                <div
                  className="login-page__animation"
                  id="animation"
                  data-testid="animationLogin"
                >
                  <Lottie
                    options={{
                      animationData: AnimationLogo,
                    }}
                  />
                </div>
                <div className="login-page__logo-pragma" id="logo">
                  <LogoPragma />
                </div>
                <div className="login-page__title">
                  <h2 className="login-page__title-text">
                    {t('weAreTheMasters')}
                  </h2>
                  <p className="login-page__title-text login-page__title-text--p">
                    {t('ofOurFate')}
                  </p>
                  <br />
                  <br />
                  <br />
                  <button
                    className="button-primary--white"
                    onClick={handleAccessAdmin}
                  >
                    <img src={logoGoogle}></img>
                    <h1 className="googleText"> Iniciar sesión con Google</h1>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Seo>
    </>
  );
};
export default LoginPanel;
