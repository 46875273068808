import {
  logoPragmaBanner,
  IconClose,
  settings,
} from 'utils/assets';
import styles from './AdminPanelForm.module.scss';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { catalogoLenguage } from 'utils/helpers/dictionaries';
import { useGetParameterQuery } from 'services/talento';
import { greenListParameter } from 'utils/constants';
import { Link } from 'react-router-dom';
export default function HeaderAdmin() {
  const [isVisibleClose, setisVisibleClose] = useState(false);
  const [isGreenList, setIsGreenList] = useState(false)
  const [iniciales, setIniciales] = useState('');

  const { data: parameterName, refetch: refetchParameter } =
    useGetParameterQuery({
      parameterName: greenListParameter
    })
  const displayGreenList = parameterName?.value

  useEffect(() => {
    const obtenerIniciales = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const correo = user.attributes.email;
      const partes = correo.split(catalogoLenguage.signal)[0].split(catalogoLenguage.point);
      const iniciales = partes
        .map((part: string[]) => part[0].toUpperCase())
        .join('');
      setIniciales(iniciales);
    };
    obtenerIniciales();
  }, []);

  useEffect(() => {
    if (displayGreenList) {
      setIsGreenList(displayGreenList === 'true');
    }
  }, [displayGreenList])

  const handlerSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) { }
  };
  const HandleHola = () => {
    if (isVisibleClose) {
      setisVisibleClose(false);
    } else {
      setisVisibleClose(true);
    }
  };
  return (
    <header className={styles.header}>
      <div className={styles.header__purpleLine}>
        <div className={styles.header__purpleLine__shadow} />
        <div className={styles.header__purpleLine__shadow} />
      </div>
      <section className={styles.header__content}>
        <section className={styles.header__content__logo}>
          <img src={logoPragmaBanner} />
        </section>
        <div className={styles.header__content__title}>
          <h1 className={styles.header__content__title__text}>Panel administrativo</h1>
        </div>
        <div className={styles.header__content__user}>
          <div className={styles.header__content__user__actionButton}>
            {isGreenList && (
              <Link to={'/admin/greenlist'}>
                <button>
                  <img
                    src={settings}
                    alt={'icon-settings'}
                  />
                </button>
              </Link>
            )}
            <p onClick={HandleHola}>{iniciales}</p>

            <ul className={styles.menu}>
              <li className={styles.menu__menuItem}>
                <button
                  className={isVisibleClose ? styles.visibleButton : styles.noVisibleButton}
                  disabled={false}
                  onClick={handlerSignOut}
                >
                  <img src={IconClose}></img>
                  Cerrar Sesión
                </button>
              </li>
            </ul>
          </div>
        </div>

      </section>
    </header>
  )
}
