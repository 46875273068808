import moment from 'moment';
import {
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';

import { ACalendar, Input, Label, Select } from 'components/atoms';
import { Section } from 'components/molecules/TabPanel/TabPanel';
import useCatalog from 'hooks/useCatalogs/useCatalogs';
import { ReactComponent as WarningIcon } from 'utils/assets/images/warningIcon.svg';
import { identificationTypeList } from 'utils/constants';
import { prepareDataCountries } from 'utils/helpers';
import { catalogoPersonal } from 'utils/helpers/dictionaries';
import { formTypes, ICity, ICountry, IDepartment, IProfileForm } from 'utils/interfaces';

import './PersonalInfo.scss';

interface IProps {
  handleSubmit: UseFormHandleSubmit<IProfileForm>;
  saveData: () => void;
  formValues: IProfileForm;
  isDisabled: boolean;
  countries: ICountry[];
  departments: IDepartment[];
  cities: ICity[];
  register: UseFormRegister<IProfileForm>;
  changeData: (value: { name: formTypes; value?: number | string }) => Promise<void>;
  errors: FieldErrorsImpl<IProfileForm>;
  setIsSelected: (value: boolean) => void;
  t: (idText: string, options?: object) => string;
  setMarginForCalendar: (state: number) => void;
  isOpenCalendar: boolean;
  setIsOpenCalendar: (value: boolean) => void;
  isEditing: boolean;
  getValues: UseFormGetValues<IProfileForm>;
}

export const PersonalInfo = ({
  handleSubmit,
  saveData,
  formValues,
  register,
  isDisabled,
  countries,
  departments,
  cities,
  changeData,
  errors,
  setIsSelected,
  t,
  setMarginForCalendar,
  isOpenCalendar,
  setIsOpenCalendar,
  isEditing,
  getValues,
}: IProps) => {
  const { data: bloodGroup } = useCatalog(catalogoPersonal.bloodGroup);
  const { country } = getValues();

  return (
    <Section id={0}>
      <div className='container-form' data-testid='form'>
        <form className='form' onSubmit={handleSubmit(saveData)}>
          <section className='section-form'>
            <aside className='section-form__aside'>
              <Input
                id='email'
                label='E-mail'
                type='email'
                placeholder='usuario@gmail.com'
                value={formValues.email}
                disabled={!isEditing}
                register={register}
                className={isEditing ? 'input_email' : ''}
                labelClassName={isEditing ? 'label_email' : ''}
              />
              <div className='section-form__aside--doble-side'>
                <div className='section-form__aside--doble-side-B'>
                  <Input
                    id='phoneNumber'
                    label='Celular'
                    placeholder=''
                    type='tel'
                    value={formValues.phoneNumber}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      changeData({ name: 'phoneNumber', value });
                    }}
                    disabled={isDisabled}
                    register={register}
                    error={!!errors?.phoneNumber}
                    subtext={isEditing ? 'Ingresa el número de indicativo' : ''}
                  />
                </div>
              </div>
            </aside>

            <aside className='section-form__aside'>
              <article className='section-form__article'>
                {isDisabled ? (
                  <Input
                    id='country'
                    label='País de residencia'
                    placeholder=''
                    type='text'
                    value={countries.find((c) => c.paisId === country)?.name || country}
                    disabled
                  />
                ) : (
                  <Select
                    className='section-form__select'
                    htmlForLabel={'country'}
                    selected={formValues.country}
                    disabled={isDisabled || !countries}
                    handleChange={(value) => {
                      changeData({ name: 'country', value });
                      changeData({ name: 'department', value: '' });
                      changeData({ name: 'city', value: '' });
                    }}
                    options={countries && prepareDataCountries(countries)}
                    textLabel={'País de residencia'}
                    placeholder={'Selecciona ...'}
                    error={!!errors.country}
                  />
                )}
              </article>
              <article className='section-form__article'>
                {isDisabled ? (
                  <Input
                    id='department'
                    label='Departamento de residencia'
                    placeholder=''
                    type='text'
                    value={
                      departments.find((d) => d.id === formValues.department)?.name ||
                      formValues.department
                    }
                    disabled
                  />
                ) : (
                  <Select
                    htmlForLabel={'department'}
                    selected={formValues.department}
                    disabled={isDisabled || !departments || !formValues.country}
                    handleChange={(value) => {
                      setIsSelected(true);
                      changeData({ name: 'department', value });
                      changeData({ name: 'city', value: '' });
                    }}
                    options={departments?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    textLabel={'Departamento de residencia'}
                    placeholder={'Selecciona ...'}
                    error={!!errors.department}
                  />
                )}
              </article>
            </aside>

            <aside className='section-form__aside'>
              <article className='section-form__article'>
                {isDisabled ? (
                  <Input
                    id='city'
                    label='Ciudad de residencia'
                    placeholder=''
                    type='text'
                    value={cities.find((d) => d.id === formValues.city)?.name || formValues.city}
                    disabled
                  />
                ) : (
                  <Select
                    htmlForLabel={'city'}
                    selected={formValues.city}
                    disabled={isDisabled || !cities || !formValues.department}
                    handleChange={(value) => {
                      changeData({ name: 'city', value });
                    }}
                    options={cities?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    textLabel={'Ciudad de residencia'}
                    placeholder={'Selecciona...'}
                    error={!!errors.city}
                  />
                )}
              </article>
              <article className='section-form__article'>
                <Input
                  id='direction'
                  label='Dirección de residencia'
                  placeholder='Tú dirección'
                  value={formValues.direction}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    changeData({ name: 'direction', value });
                  }}
                  disabled={isDisabled}
                  subtext={
                    isEditing
                      ? 'Especifica: calle / carrera / transversal / diagonal / avenida'
                      : ''
                  }
                  error={!!errors.direction}
                />
              </article>
            </aside>

            <aside className='section-form__aside'>
              {isDisabled ? (
                <Input
                  id='bloodType'
                  label='Grupo sanguíneo'
                  placeholder='Tú grupo sanguíneo'
                  type='text'
                  value={formValues.bloodType}
                  disabled
                />
              ) : (
                <Select
                  htmlForLabel={'bloodType'}
                  selected={formValues.bloodType}
                  disabled={isDisabled}
                  placeholder={'Tú grupo sanguíneo'}
                  handleChange={(value) => changeData({ name: 'bloodType', value })}
                  value={{ value: formValues.bloodType, label: formValues.bloodType }}
                  options={bloodGroup?.map((value) => ({
                    value,
                    label: value,
                  }))}
                  textLabel={'Grupo sanguíneo (opcional)'}
                  error={!!errors.bloodType}
                />
              )}
              {isDisabled ? (
                <Input
                  id='idType'
                  label='Tipo de documento'
                  placeholder=''
                  type='text'
                  value={formValues.idType}
                  disabled
                />
              ) : (
                <Select
                  htmlForLabel={'idType'}
                  selected={formValues.idType}
                  disabled={isDisabled}
                  handleChange={(value) => changeData({ name: 'idType', value })}
                  options={identificationTypeList?.map((identificationType) => ({
                    value: identificationType,
                    label: identificationType,
                  }))}
                  textLabel={'Tipo de documento'}
                  placeholder={'Selecciona ...'}
                  error={!!errors.idType}
                />
              )}
            </aside>

            <aside className='section-form__aside'>
              <article className='section-form__article'>
                <Input
                  id='numberId'
                  label='Número de documento'
                  placeholder='Tú número de documento'
                  value={formValues.numberId}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    changeData({ name: 'numberId', value });
                  }}
                  disabled={isDisabled}
                  register={register}
                  error={!!errors.numberId}
                />
              </article>

              <article className='section-form__article'>
                {isDisabled ? (
                  <Input
                    id='bornDate'
                    label='Fecha de nacimiento'
                    placeholder=''
                    type='text'
                    value={formValues.bornDate}
                    disabled
                  />
                ) : (
                  <>
                    <Label text={'Fecha de nacimiento'} htmlFor='bornDate' />
                    <ACalendar
                      birthdateForm={moment(formValues.bornDate)}
                      updateBirthdate={(date) => changeData({ name: 'bornDate', value: date })}
                      disabled={isDisabled}
                      setMarginForCalendar={setMarginForCalendar}
                      isOpenCalendar={isOpenCalendar}
                      setIsOpenCalendar={setIsOpenCalendar}
                      error={!!errors.bornDate}
                    />
                    {errors.bornDate && (
                      <div className='subtext-error'>
                        <WarningIcon />
                        <span>Campo obligatorio</span>
                      </div>
                    )}
                  </>
                )}
              </article>
            </aside>
          </section>
        </form>
      </div>
    </Section>
  );
};
