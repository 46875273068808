import { useState } from 'react';
import styles from './AlertMessage.module.scss';
import { alertExclamationWhite, xCloseIconWhite } from 'utils/assets';

interface AlertMessageProps {
  message: string;
  onClose: () => void;
}

const AlertMessage = ({ message, onClose }: AlertMessageProps) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    onClose(); // Notifica a ProfilePage que el alert se cerró
  };

  if (!visible) return null; 
  return (
    <section className={styles.alert}>
        <div className={styles.alertMessage}>
            <img src={alertExclamationWhite} alt="exclamación" className={styles.alertMessage__icon}/>
            <p className={styles.alertMessage__text}>{message}</p>
        </div>
        <img src={xCloseIconWhite} alt="x" className={styles.alert__icon} onClick={handleClose}/> 
    </section>
  );
};

export default AlertMessage;
