import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { RootState } from 'store/store';
import { ICustomError } from 'utils/interfaces';

export const talentoApi = createApi({
    reducerPath: 'talentoApi',  
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_TALENTO_URL,
        prepareHeaders: async (headers, { getState }) => {
            try {
                const data = await Auth.currentSession();
                const authToken = data.getIdToken().getJwtToken();
                headers.set('Authorization', `Bearer ${authToken}`);
            } catch (error) { }

            const languageCode =
                (getState() as RootState).translations.languageCode || 'es';
            headers.set('Accept-language', `${languageCode}`);

            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, {}>,

    endpoints: (builder) => ({}),
});

