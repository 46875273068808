import { identificationTypes } from 'utils/helpers/dictionaries';

export const identificationTypeList: identificationTypes[] = [
  identificationTypes.cc,
  identificationTypes.ce,
  identificationTypes.ti,
  identificationTypes.passport,
  identificationTypes.cip,
  identificationTypes.dni,
  identificationTypes.dpi,
  identificationTypes.pep,
  identificationTypes.ppt,
  identificationTypes.id,
  identificationTypes.crp,
  identificationTypes.cex,
];
