import React from 'react'
import { ContentNotFound } from 'components/atoms/ContentNotFound/ContentNotFound.components'
import { useTranslation } from 'hooks/useTranslation';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { decorativeStrokeLeft, decorativeStrokeRight } from 'utils/assets';
import './SupportBoundary.scss';
import { AButton } from 'components/atoms';
interface Error {
    support?: boolean
}

const SupportBoundary = ({ support }: Error) => {

    const { translations, isLoading } = useAppSelector(selectTranslations);
    const { t } = useTranslation(translations, isLoading);

    return (

        <div className='support-boundary' data-testid="container__error">                
            <img
                src={decorativeStrokeLeft}
                alt="Decorative stroke left"
                className="decorative__stroke decorative__stroke__left"
            />
            
            <img
                src={decorativeStrokeRight}
                alt="Decorative stroke right"
                className="decorative__stroke decorative__stroke__right"
            />

            <div className='support-boundary__content'>
                <div className='support-boundary__eMantenimiento'>
                    <ContentNotFound error={t("maintenanceMessage")} />
                </div>

                <AButton className='button-primary--white error-custom__button' tag='a' url='https://www.pragma.co/es/carrera/cultura-pragma/'>Conoce nuestra cultura</AButton>
            </div>
        </div>

    )


}

export default SupportBoundary