import HeaderAdmin from "components/organisms/AdminPanelForm/HeaderAdmin.component"
import Footer from "components/molecules/Footer/Footer.component"
import { trash, chevronLeftWhite } from "utils/assets"

import './AdminGreenPage.scss'
import { useCreateGreenListMutation, useDeleteGreenListMutation, useGetGreenListQuery } from "services/greenlist"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { greenListFormSchema } from "utils/schemas/greenListForm.schema"
import { Link } from "react-router-dom"

const AdminGreenPage = () => {
    const { register, handleSubmit, reset } = useForm<{ email: string }>({
        mode: 'onChange',
        defaultValues: { email: '' },
        resolver: yupResolver(greenListFormSchema)
    })

    const { data: greenList, refetch } = useGetGreenListQuery({})
    const [createUser] = useCreateGreenListMutation()
    const [deleteUser] = useDeleteGreenListMutation()

    useEffect(() => {
        refetch()
    }, [AdminGreenPage])

    const handleCreateUser = async (data: any) => {
        const { email } = data
        const saveUserResponse = await createUser({
            userId: email
        }).unwrap();

        refetch();
        reset();
    }

    const handleDeleteUser = async (email: string) => {
        if (!email) return

        const deleteUserResponse = await deleteUser({
            userId: email
        }).unwrap();

        refetch();
    }

    return (
        <>
            <HeaderAdmin />
            <div className="bgGrey">
                <div className="searchBar">
                    <div className="searchBar__title">
                        <Link to={'/admin/nuevospragmaticos'}>
                            <button>
                                <img
                                    src={chevronLeftWhite}
                                    alt={'icon-chevron-left'}
                                />
                            </button>
                        </Link>
                        <h1>Green list</h1>
                    </div>
                    <div className="searchBar__form">
                        <label>Añadir correo autorizado</label>
                        <form onSubmit={handleSubmit(handleCreateUser)}>
                            <div className="searchBar__form--field">
                                <div className="searchBar__input">
                                    <input
                                        type="text"
                                        required
                                        placeholder="Correo electronico"
                                        {...register('email')}
                                    />
                                </div>
                                <button className="searchBar__button">Añadir</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div style={{ marginTop: '60px', width: '35%' }}>
                    <div className="tableContainer">
                        <div className="scrollTable">
                            <table className="atomTable">
                                <thead>
                                    <tr>
                                        <th>Correo electrónico</th>
                                    </tr>
                                </thead>
                                <tbody className="atomTable__tableBody">
                                    {greenList?.map(({ userId }: any) => (
                                        <tr className="atomTable__tableBody__tableRow">
                                            <td>
                                                {userId}

                                                <button
                                                    onClick={() => handleDeleteUser(userId)}
                                                    className="actionbutton"
                                                >
                                                    <img
                                                        src={trash}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-admin-container">
                <Footer isDark />
            </div>
        </>
    )
}

export default AdminGreenPage