import './FormTab.scss';
import cn from 'classnames';

import { checkIcon, iconError, iconWarning } from '../../../utils/assets';
import { AImage } from '../AImage/AImage.components';
import { forwardRef } from 'react';

export interface FormTabProps {
  selected?: boolean;
  disabled?: boolean;
  status?: 'success' | 'error' | 'none';
  children?: React.ReactNode;
  onClick?: () => void;
  locked?: boolean; 
}

  const FormTab = forwardRef<HTMLDivElement, FormTabProps>(({
    selected = false,
    status,
    disabled = false,
    children,
    onClick,
    locked = false
  }, ref) => {
    const formTabClass = cn('form-tab', {
      'form-tab--selected': selected && !disabled,
      'form-tab--disabled': disabled,
      'form-tab--locked': locked
    });
  
    const iconClass = cn('form-tab__icon', {
      'form-tab__icon--error': status === 'error',
      'form-tab__icon--success': status === 'success',
    });
  
    function tabIcon() {
      if (status) {
        if (status === 'none') return;
        const icon = status === 'success' ? checkIcon : iconWarning;
        return <AImage url={icon} width={24} height={24} alt="status icon" />;
      } else {
        return <div className="form-tab__icon--default"></div>;
      }
    }
  
    return (
      <div className={formTabClass} onClick={onClick} ref={ref}>
        {!disabled && <div role="status" className={iconClass}>{tabIcon()}</div>}
        <div className="form-tab__content">{children}</div>
      </div>
    );
  });
  
  export default FormTab;
