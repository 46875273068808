import ReactDOM, { createPortal } from 'react-dom';
import { AButton, AImage, Loader, TextIcon } from 'components/atoms';
import {
  xMarkIcon,
  checkIcon,
  inProgress,
  iconError,
  inProgressModal,
  iconQuestion,
  alertIcon,
} from 'utils/assets/';
import './ModalAlert.scss';
import { calendarIcon } from 'utils/assets';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { useTranslation } from 'hooks/useTranslation';

export type typeAlert =
  | 'success'
  | 'error'
  | 'inProgress'
  | 'loading'
  | 'send'
  | 'adjuntLink'
  | 'question'
  | 'input'
  | 'normal'
  | 'warning';

export enum TypeAlertValues {
  success = 'success',
  error = 'error',
  inProgress = 'inProgress',
  loading = 'loading',
  send = 'send',
  adjuntLink = 'adjuntLink',
  question = 'question',
  input = 'input',
  normal = 'normal',
  warning = 'warning',
}

interface IProps {
  alertType?: typeAlert;
  closeIcon?: boolean;
  alertTitle?: string;
  alertText?: string;
  headerMessage?: string;
  isOpened: boolean;
  toggleModal: (state: boolean) => void;
  children?: React.ReactNode;
  width?: number;
  height?: string;
  confirmButton?: boolean;
  disabledButton?: boolean;
  handleClick?: () => void;
  cancelButton?: boolean;
  cancelFunction?: () => void;
  setLinkUrl?: (state: string) => void;
  customIcon?: string;
  customConfirmButton?: string;
  customCancelButton?: string;
}

interface IAlerts {
  success: string;
  error: string;
  inProgress: string;
  loading: string;
  send: string;
  question: string;
  exit?: string;
  adjuntLink: string;
  input: string;
  normal: string;
  warning: string;
}

export const ModalAlert = (props: IProps) => {
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const container = document.body;

  const {
    handleClick,
    alertType,
    alertText,
    isOpened,
    toggleModal,
    children,
    width,
    height,
    closeIcon,
    confirmButton,
    disabledButton,
    cancelButton,
    cancelFunction,
    alertTitle,
    headerMessage = '',
    setLinkUrl = () => { },
    customIcon,
    customCancelButton = '',
    customConfirmButton = ''
  } = props;
  const alertImages: IAlerts = {
    success: checkIcon,
    send: checkIcon,
    error: iconError,
    warning: alertIcon,
    inProgress: inProgressModal,
    loading: inProgress,
    question: iconQuestion,
    adjuntLink: '',
    input: '',
    normal: '',
  };
  const alertLabels: IAlerts = {
    success: t('labelModalSucess'),
    send: t('labelModalSend'),
    error: t('labelModalError'),
    inProgress: t('labelModalProgress'),
    loading: '',
    question: t('labelModalQuestion'),
    adjuntLink: '',
    input: '',
    normal: '',
    warning: '',
  };
  const alertButtons: IAlerts = {
    success: t('buttonModalSucess'),
    send: t('buttonModalSend'),
    error: t('buttonModalError'),
    inProgress: t('buttonModalProgress'),
    loading: t('buttonModalLoading'),
    question: 'Salir',
    adjuntLink: 'Compartir',
    input: 'Validar',
    normal: 'ok',
    warning: 'Aceptar',
  };
  return createPortal(
    <>
      {isOpened && (
        <div className="modal-alert">
          <div
            className={
              alertType === 'input'
                ? 'modal-alert__container modal-alert__input'
                : alertType === 'normal'
                  ? 'modal-large'
                  : 'modal-alert__container'
            }
            style={{ width, height }}
          >
            {headerMessage && (
              <div className="modal-alert__header">
                <h1>{headerMessage}</h1>
              </div>
            )}

            {closeIcon && (
              <AButton
                tag="a"
                dataTestId="close-button"
                className="modal-alert__close-button"
                handleClick={
                  handleClick && handleClick !== undefined
                    ? handleClick
                    : () => toggleModal(!isOpened)
                }
              >
                <AImage
                  url={xMarkIcon}
                  width={17}
                  height={17}
                  alt="Close icon"
                />
              </AButton>
            )}
            {!children ? (
              <div
                className={
                  alertType === 'error'
                    ? 'modal-alert__content modal-alert__error'
                    : 'modal-alert__content'
                }
              >
                {alertType === 'loading' ? (
                  <Loader className="modal-alert__animation" />
                ) : (
                  <AImage
                    className={`modal-alert__image ${!customIcon && alertType === 'error' && 'modal-alert__image--error'
                      } ${!customIcon && alertType === 'success' && 'modal-alert__image--success'
                      }`}
                    url={customIcon || alertImages[alertType as keyof IAlerts]}
                    alt="Alert image"
                  />
                )}

                {alertType === 'adjuntLink' && (
                  <div className="modal-alert__ContenedorLink">
                    <label className="modal-alert__ContenedorLink__labelLink">
                      {t('insertLink')}
                    </label>
                    <input
                      type="text"
                      className="modal-alert__ContenedorLink__inputLink"
                      onChange={(e) => {
                        setLinkUrl(e.target.value);
                      }}
                    />
                  </div>
                )}

                {alertType === 'normal' && (
                  <div className="modal-alert__ContenedorLink">
                    <label className="modal-alert__ContenedorLink__labelLink">
                      Confirmación de la entrevista
                    </label>
                    <TextIcon
                      className="interviewInfo__icon"
                      urlIcon={calendarIcon}
                      tag="text"
                      alt="calendar"
                      text={''}
                    />
                  </div>
                )}
                <div className="modal-alert__container--text">
                  <h3 className="modal-alert__tittle">
                    {alertTitle
                      ? alertTitle
                      : alertLabels[alertType as keyof IAlerts]}
                  </h3>
                  <p className="modal-alert__text"> {alertText} </p>

                  {alertType === 'input' && (
                    <input
                      type="text"
                      className="modal-alert__ContenedorLink__inputLink"
                      onChange={(e) => {
                        setLinkUrl(e.target.value);
                      }}
                    />
                  )}
                  <div className="modal-alert__buttons-wrap">
                    {cancelButton && (
                      <AButton
                        dataTestId="cancel-button"
                        tag="button"
                        style={{ width: '139px' }}
                        handleClick={cancelFunction}
                        disabled={disabledButton ? disabledButton : false}
                        className="modal-alert__button--cancel"
                      >
                        {customCancelButton ? customCancelButton : 'Cancelar'}
                      </AButton>
                    )}
                    {confirmButton && (
                      <AButton
                        dataTestId="confirm-button"
                        tag="button"
                        style={{ width: '139px' }}
                        handleClick={
                          handleClick && handleClick !== undefined
                            ? handleClick
                            : () => toggleModal(!isOpened)
                        }
                        disabled={disabledButton ? disabledButton : false}
                        className="button-primary modal-alert__button"
                      >
                        {alertType === 'adjuntLink'
                          ? 'Compartir'
                          : alertType === 'inProgress'
                            ? 'Aceptar'
                            : customConfirmButton
                              ? customConfirmButton
                              : 'Aceptar'}
                      </AButton>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      )}
    </>,
    container
  );
};
