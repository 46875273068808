import { sapApi } from './../services/sap';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { talentoApi } from 'services/talentoAPI/index';
import { generalApi } from 'services/general';

import uiReducer, { initialState as initialStateUi } from './ui/ui.slice';
import timerReducer from './timer/timer.slice';
import authenticationReducer, {
  initialStateAuth,
} from './authentication/authentication.slice';
import userReducer, { initialStateUser } from './user/user.slice';
import translationsReducer from './translation/translation.slice';
import salaryOptionsReducer from 'components/organisms/SalaryOptions/stores/salaryOptions.slice';
import { talentoAPI } from 'lib/talentoAPI';
import onboardingReducer from './onboarding/onboarding.slice';
import { greenApi } from 'services/greenlist';

const combinedReducer = combineReducers({
  ui: uiReducer,
  authentication: authenticationReducer,
  timer: timerReducer,
  user: userReducer,
  translations: translationsReducer,
  salaryOptions: salaryOptionsReducer,
  onboarding: onboardingReducer,
  [talentoApi.reducerPath]: talentoApi.reducer,
  [generalApi.reducerPath]: generalApi.reducer,
  [sapApi.reducerPath]: sapApi.reducer,
  [greenApi.reducerPath]: greenApi.reducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logout') {
    state = {
      ...state,
      ui: initialStateUi,
      authentication: initialStateAuth,
      user: initialStateUser,
    };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(talentoApi.middleware)
      .concat(generalApi.middleware)
      .concat(sapApi.middleware)
      .concat(greenApi.middleware),
});

export const setupStore = () =>
  configureStore({
    reducer: {
      ui: uiReducer,
      authentication: authenticationReducer,
      timer: timerReducer,
      user: userReducer,
      translations: translationsReducer,
      salaryOptions: salaryOptionsReducer,
      onboarding: onboardingReducer,
      [talentoApi.reducerPath]: talentoApi.reducer,
      [generalApi.reducerPath]: generalApi.reducer,
      [sapApi.reducerPath]: sapApi.reducer,
      [greenApi.reducerPath]: greenApi.reducer
    },
  });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;

/* export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>; */
