import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { setUserInfo } from 'store';
import { selectUserInfo } from 'store/user/user.slice';
import { setLanguageCode } from 'store/translation/translation.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGetMaintenanceFromStageQuery } from 'services/talento';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';
import { DATA_ROUTE_ERROR, EMAIL_SEARCH_PARAM, ROUTES } from 'utils/constants';
import { AT, GOOGLE } from 'utils/constants/specialCharacters';
import { ID_MAINTENANCE, LoginPanelConst, calendarConstant } from 'utils/helpers/dictionaries';

import { Loader } from 'components/atoms';
import SupportBoundary from 'components/organisms/SupportBoundary/SupportBoundary';
import LoginPanel from 'components/organisms/AdministrativePanelLogin/LoginPanel.components';
import AdminPanelForm from 'components/organisms/AdminPanelForm';
import { CompletedAdminForm } from 'components/organisms/CompletedAdminForm';
import { AuthenticationPage, LoginPage } from 'pages/Authentication';
import AdminPage from 'pages/Dashboard/AdminPage/AdminPage.component';
import PrivateRoutesAdmin from './PrivateRoutesAdmin';
import { NotificationContextProvider } from 'components/organisms/NotificationsPanel/NotificationContext';
import AdminGreenPage from 'pages/Dashboard/AdminGreenPage/AdminGreenPage.component';
const DashboardRoutes = React.lazy(() => import('./DashboardRoutes'));

export const AppRoutes: React.FC = () => {
  const userInfo = useAppSelector(selectUserInfo);
  const dispatch = useAppDispatch();

  const { data: stageData, error: stageError } = useGetMaintenanceFromStageQuery(ID_MAINTENANCE.general);

  const [support, setSupport] = useState(false);
  const [datos, setDatos] = useState<any>([]);

  useEffect(() => {
    loadDataFromLocalStorage();
  }, []);

  const loadDataFromLocalStorage = async () => {
    const storedDatos = localStorage.getItem(LoginPanelConst.datos);

    if (storedDatos) {
      setDatos(JSON.parse(storedDatos) || []);
    }
  };

  const getRoutes = () => {
    if (!Array.isArray(datos)) {
      console.error(DATA_ROUTE_ERROR);
      return [];
    }

    return datos.filter((item) => item.path).map((item) => item.path);
  };

  useEffect(() => {
    if (stageData) {
      setSupport(stageData?.data.value === 'true')
    }
  }, [stageData, stageError]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        dispatch(
          setUserInfo({
            ...userInfo,
            email: currentUser.username,
            isLogged: true,
          })
        );
      } catch (e) {
        console.error(e);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    dispatch(setLanguageCode(calendarConstant.es));
  }, []);

  const isValidUser = () => {
    return userInfo.email.includes(GOOGLE) && !userInfo.email.includes(AT);
  };

  return (
    <BrowserRouter>
      <NotificationContextProvider email={userInfo.email}>
        {!support ? (
          <div>
            <Routes>
              <Route
                path={ROUTES.APP_ROUTES.LOGIN}
                element={
                  <PublicRoutes isLogged={userInfo.isLogged}>
                    <LoginPage />
                  </PublicRoutes>
                }
              />
              <Route
                path={ROUTES.APP_ROUTES.RESTORE_PASSWORD}
                element={
                  <PublicRoutes isLogged={userInfo.isLogged}>
                    <AuthenticationPage />
                  </PublicRoutes>
                }
              />

              <Route
                element={
                  <PrivateRoutesAdmin
                    isAuthenticated={
                      userInfo.isLogged &&
                      getRoutes().includes(
                        ROUTES.APP_ROUTES.ADMIN.NEW_PRAGMA_CANDIDATES
                      )
                    }
                    redirectPath={ROUTES.APP_ROUTES.ADMIN.ROOT}
                  />
                }
              >
                <Route
                  path={
                    ROUTES.APP_ROUTES.ADMIN.ROOT +
                    ROUTES.APP_ROUTES.ADMIN.NEW_PRAGMA_CANDIDATES
                  }
                  element={<AdminPage />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutesAdmin
                    isAuthenticated={
                      userInfo.isLogged &&
                      getRoutes().includes(
                        ROUTES.APP_ROUTES.ADMIN.GREEN_LIST
                      )
                    }
                    redirectPath={ROUTES.APP_ROUTES.ADMIN.ROOT}
                  />
                }
              >
                <Route
                  path={
                    ROUTES.APP_ROUTES.ADMIN.ROOT +
                    ROUTES.APP_ROUTES.ADMIN.GREEN_LIST
                  }
                  element={<AdminGreenPage />}
                />
              </Route>

              <Route
                element={
                  <PrivateRoutesAdmin
                    isAuthenticated={
                      userInfo.isLogged &&
                      getRoutes().includes(ROUTES.APP_ROUTES.ADMIN.CANDIDATE_FORM)
                    }
                    redirectPath={ROUTES.APP_ROUTES.ADMIN.ROOT}
                  />
                }
              >
                <Route
                  path={
                    ROUTES.APP_ROUTES.ADMIN.ROOT +
                    ROUTES.APP_ROUTES.ADMIN.CANDIDATE_FORM +
                    EMAIL_SEARCH_PARAM
                  }
                  element={<AdminPanelForm />}
                />
              </Route>
              <Route
                element={
                  <PrivateRoutesAdmin
                    isAuthenticated={
                      userInfo.isLogged &&
                      getRoutes().includes(ROUTES.APP_ROUTES.ADMIN.CANDIDATE_FORM)
                    }
                    redirectPath={ROUTES.APP_ROUTES.ADMIN.ROOT}
                  />
                }
              >
                <Route
                  path={
                    ROUTES.APP_ROUTES.ADMIN.ROOT +
                    ROUTES.APP_ROUTES.ADMIN.COMPLETE_CANDIDATE_FORM +
                    EMAIL_SEARCH_PARAM
                  }
                  element={<CompletedAdminForm />}
                />
              </Route>

              <Route
                path={ROUTES.APP_ROUTES.ADMIN.ROOT}
                element={<LoginPanel />}
              />

              <Route
                path="/*"
                element={
                  <PrivateRoutes
                    isLoggedAdmin={
                      userInfo.isLogged &&
                      getRoutes().includes(
                        ROUTES.APP_ROUTES.ADMIN.NEW_PRAGMA_CANDIDATES
                      ) &&
                      isValidUser()
                    }
                    isLogged={userInfo.isLogged}
                  >
                    <Suspense fallback={<Loader />}>
                      <DashboardRoutes />
                    </Suspense>
                  </PrivateRoutes>
                }
              />
            </Routes>
          </div>
        ) : (
          <div>
            <Routes>
              <Route path={ROUTES.ALL} element={<SupportBoundary />} />
            </Routes>
          </div>
        )}
      </NotificationContextProvider>
    </BrowserRouter>
  );
};
