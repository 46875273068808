import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { AButton } from 'components/atoms';
import { ModalAlert } from 'components/molecules';
import AlertMessage from 'components/molecules/AlertMessage/AlertMessage';
import { ProfileCircleCard } from 'components/molecules/ProfileCircleCard/ProfileCircleCard.components';
import { Section, TabPanel, TabList, Tab } from 'components/molecules/TabPanel/TabPanel';
import { ChangePasswordForm } from 'components/organisms';
import useGetName from 'hooks/useGetName';
import { useTranslation } from 'hooks/useTranslation';
import {
  useGetCountriesQuery,
  useGetDepartmentsByCountryQuery,
  useGetCitiesByDepartmentQuery,
} from 'services/general';
import { useGetProfileDataQuery, useUpdateProfileDataMutation } from 'services/talento';
import { selectUserInfo, selectVacantInfo } from 'store';
import { useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { EditButtonPen } from 'utils/assets';
import { ALERT_TEXT } from 'utils/constants/textChangePassword';
import { formatCountryOrState } from 'utils/helpers';
import {
  IResponseProfile,
  IResponseCountries,
  IResponseDepartments,
  IResponseCities,
  IProfileForm,
  IDepartment,
  IModal,
  ICity,
  formTypes,
} from 'utils/interfaces';
import { getProfileFormSchema } from 'utils/schemas';

import { ContainerLoader } from './ContainerLoader/ContainerLoader';
import { PersonalInfo } from './PersonalInfo/PersonalInfo';

import './ProfileForm.scss';
interface IProps {
  isDisabled: boolean;
  changeState: () => void;
  closeSesion: () => void;
  setMarginForCalendar: (state: number) => void;
  setIsDisabled: (value: boolean) => void;
}

const INITIAL_DATA = {
  email: '',
  phoneNumber: '',
  city: undefined,
  department: undefined,
  country: undefined,
  phoneCode: undefined,
  direction: '',
  bloodType: '',
  idType: '',
  numberId: '',
  bornDate: '',
};

export const PerfilForm = ({
  isDisabled,
  setIsDisabled,
  changeState,
  closeSesion,
  setMarginForCalendar,
}: IProps) => {
  const [localDepartments, setLocalDepartments] = useState<IDepartment[]>([]);
  const [localCities, setLocalCities] = useState<ICity[]>([]);
  const [isOriginData, setIsOriginData] = useState<boolean>(false);
  const [modal, setModal] = useState<IModal>({
    message: '',
    type: 'success',
    isOpen: false,
    handleClick: () => {},
  });
  const [hasGritInformation, setHasGritInformation] = useState<boolean>(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);

  const [formValues, setFormValues] = useState<IProfileForm>(INITIAL_DATA);
  const userInfo = useAppSelector(selectUserInfo);
  const { email } = userInfo;
  const { vacant } = useAppSelector(selectVacantInfo);
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const profileFormSchema = getProfileFormSchema(hasGritInformation);

  const { firstLastNameComplete, firstNameComplete } = useGetName();

  const [showAlert, setShowAlert] = useState<boolean>(true);
  const [isEditing, setIsEditing] = useState(true);

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
    isFetching: isFetchingProfile,
  } = useGetProfileDataQuery<IResponseProfile>({ email });

  const { data: countries, isLoading: isLoadingCountries } =
    useGetCountriesQuery<IResponseCountries>();

  const { data: departments } = useGetDepartmentsByCountryQuery<IResponseDepartments>(
    formValues.country,
    {
      skip: !formValues.country,
    }
  );

  const { data: cities } = useGetCitiesByDepartmentQuery<IResponseCities>(formValues.department, {
    skip: !formValues.department,
  });

  const [
    updateProfileData,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, error: errorUpdate },
  ] = useUpdateProfileDataMutation();

  const changeData = async ({ name, value }: { name: formTypes; value?: number | string }) => {
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setValue(name, value, { shouldValidate: true });
  };

  useEffect(() => {
    setShowAlert(true);
  }, []);

  const handleSaveOrEdit = async () => {
    if (isEditing) {
      saveData();
      currentTab === 1 && savePasswordData();
    }
    setIsDisabled(false);
    setIsEditing(!isEditing);
  };

  const fillDefaultData = () => {
    if (profileData) {
      !formValues.email && changeData({ name: 'email', value: email });

      !formValues.phoneNumber &&
        changeData({
          name: 'phoneNumber',
          value: profileData.phoneNumber,
        });

      if (countries && !formValues.country) {
        const currentCountry = countries?.find(
          (country) => country.name === formatCountryOrState(profileData.country!)
        );
        changeData({ name: 'country', value: currentCountry?.paisId });
      }

      const currentDeparment = localDepartments?.find(
        (department) => department.name === formatCountryOrState(profileData.state!)
      );
      changeData({ name: 'department', value: currentDeparment?.id });

      const currentCity = localCities?.find((city) => city.name === profileData.city!);
      changeData({ name: 'city', value: currentCity?.id });

      !formValues.direction && changeData({ name: 'direction', value: profileData.direction });

      !formValues.bloodType && changeData({ name: 'bloodType', value: profileData.bloodType });

      !formValues.idType && changeData({ name: 'idType', value: profileData.idType });

      !formValues.numberId && changeData({ name: 'numberId', value: profileData.numberId });

      !formValues.bornDate && changeData({ name: 'bornDate', value: profileData.bornDate });
    }
  };

  const saveData = () => {
    const {
      email,
      phoneNumber,
      country,
      department,
      city,
      direction,
      bloodType,
      idType,
      numberId,
      bornDate,
    } = getValues();

    const finalData = {
      email,
      phoneNumber,
      country: countries.find((opt) => opt.paisId === country)?.name ?? '',
      state: departments.find((opt) => opt.id === department)?.name ?? '',
      city: cities.find((opt) => opt.id === city)?.name ?? '',
      direction,
      bloodType,
      idType,
      numberId,
      bornDate,
    };
    updateProfileData(finalData);
  };

  const savePasswordData = () => {
    const passwordButton = document.querySelector(
      '.section-password-form .change-password-form__button button'
    ) as HTMLButtonElement | null;

    if (passwordButton) {
      passwordButton.click();
    }
  };

  const checkOriginData = () => {
    const {
      phoneNumber,
      country,
      department,
      city,
      direction,
      bloodType,
      idType,
      numberId,
      bornDate,
    } = getValues();

    const validPhone = profileData?.phoneNumber === `${phoneNumber}`;
    const currentCountry = countries?.find((opt) => opt.paisId === country);
    const validCountry = profileData?.country === currentCountry?.name;
    const currentDepartment = departments?.find((opt) => opt.id === department);
    const validDepartment = profileData?.state === currentDepartment?.name;
    const currentCity = cities?.find((opt) => opt.id === city);
    const validCity = profileData?.city === currentCity?.name;
    const validDirection = profileData?.direction === direction;
    const validBloodType = profileData?.bloodType === bloodType;
    const validIdType = profileData?.idType === idType;
    const validNumberId = profileData?.numberId === numberId;
    const validBornDate = profileData?.bornDate === bornDate;

    setIsOriginData(
      validPhone &&
        validCountry &&
        validDepartment &&
        validCity &&
        validDirection &&
        validBloodType &&
        validIdType &&
        validNumberId &&
        validBornDate
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<IProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(profileFormSchema),
  });

  const scrollToTab = (id: number | string, element: HTMLDivElement | null) => {
    if (element) {
      setCurrentTab(id as number);
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  const personalInfoFields: (keyof IProfileForm)[] = [
    'phoneNumber',
    'country',
    'department',
    'city',
    'direction',
    'bloodType',
    'idType',
    'numberId',
    'bornDate',
  ];

  const personalInfoHasErrors = personalInfoFields.some((field) => !!errors[field]);

  const personalInfoState = personalInfoHasErrors ? 'error' : 'none';

  useEffect(() => {
    if (!profileData) return;

    const { bloodType, bornDate, direction, idType, numberId } = profileData;
    const HAS_GRIT_INFORMATION =
      Boolean(bloodType) &&
      Boolean(bornDate) &&
      Boolean(direction) &&
      Boolean(idType) &&
      Boolean(numberId);

    setHasGritInformation(HAS_GRIT_INFORMATION);
  }, [profileData]);

  useEffect(() => {
    checkOriginData();
  }, [formValues]);

  useEffect(() => {
    departments && setLocalDepartments(departments);
  }, [departments]);

  useEffect(() => {
    cities && setLocalCities(cities);
  }, [cities]);

  useEffect(() => {
    if (!isSelected) {
      fillDefaultData();
    }
  }, [profileData, countries, localDepartments, localCities]);

  useEffect(() => {
    if (!isLoadingUpdate && isSuccessUpdate) {
      changeState();
      refetchProfile();
      setModal({ message: '¡Cambios guardados!', type: 'success', isOpen: true });
    }
    if (errorUpdate) {
      setIsDisabled(false);
      setIsEditing(true);
    }
  }, [isLoadingUpdate, isSuccessUpdate, errorUpdate]);

  const regex =
    /(\bde\b|\blos\b|\blas\b|\bdel\b)/gi; /* Filtra las palabras que no deseamos en los nombres compuestos */
  const auxName = vacant?.fullName.replace(regex, ' ');
  const splitedName = auxName?.replace(/\s{2,}/g, ' ');
  const splitedNameArray = splitedName?.split(' ');
  const firstLetterName: string = splitedNameArray?.[0]?.[0];
  let firstLetterSecondName: string;
  if (splitedNameArray?.length <= 2) {
    firstLetterSecondName = splitedNameArray?.[splitedNameArray.length - 1]?.[0];
  } else {
    firstLetterSecondName = splitedNameArray?.[splitedNameArray.length - 2]?.[0];
  }

  if (isLoadingProfile || isLoadingCountries || isLoadingUpdate || isFetchingProfile) {
    return <ContainerLoader />;
  }

  return (
    <>
      <div className='container-alert'>
        {showAlert && <AlertMessage message={ALERT_TEXT} onClose={() => setShowAlert(false)} />}
      </div>
      <div className='container-form' data-testid='form'>
        <ModalAlert
          alertType={modal.type}
          alertText={modal.message}
          alertTitle={modal.title ? modal.title : undefined}
          isOpened={modal.isOpen}
          confirmButton={true}
          toggleModal={(isOpen: boolean) => setModal((state) => ({ ...state, isOpen }))}
          handleClick={modal.handleClick}
        />

        <section className='sectionHeader'>
          <ProfileCircleCard name={firstLetterName} secondName={firstLetterSecondName} />
          <p className='sectionHeader__name'>
            {firstNameComplete} {firstLastNameComplete}
          </p>

          <AButton
            tag='a'
            className={`btn-profile button-primary`}
            disabled={
              isEditing
                ? (currentTab === 0 && isOriginData) ||
                  !isValid ||
                  (currentTab === 1 && !isValidPassword)
                : false
            }
            handleClick={handleSaveOrEdit}
          >
            <img
              src={isEditing ? '' : EditButtonPen}
              alt={isEditing ? '' : 'Editar'}
              className={` ${!isEditing ? 'sectionHeader__imgPen' : ''}`}
            />

            {isEditing ? 'Guardar' : 'Editar'}
          </AButton>
        </section>

        <div className='profile-tabs'>
          <TabPanel initialTab={0}>
            <TabList>
              <Tab id={0} onClickExtra={scrollToTab} state={personalInfoState}>
                Información personal
              </Tab>

              <Tab id={1} onClickExtra={scrollToTab}>
                Contraseña
              </Tab>
            </TabList>

            <PersonalInfo
              {...{
                handleSubmit,
                saveData,
                formValues,
                register,
                isDisabled,
                countries,
                departments,
                cities,
                changeData,
                errors,
                setIsSelected,
                t,
                setMarginForCalendar,
                isOpenCalendar,
                setIsOpenCalendar,
                isEditing,
                getValues,
              }}
            />

            <Section id={1}>
              <div className='section-password-form'>
                <ChangePasswordForm
                  profilePage={true}
                  onValidForm={setIsValidPassword}
                  isEditing={isEditing}
                />
              </div>
            </Section>
          </TabPanel>
        </div>
      </div>
    </>
  );
};
