export enum routes {
  home = '/home',
  homeHiring = '/home-hiring',
  welcome = '/welcome',
}

export const DATA_ROUTE_ERROR = 'El estado "datos" no es un array.';

export const EMAIL_SEARCH_PARAM = '/:email';
export const ID_SEARCH_PARAM = '/:id'

export const ROUTES = {
  ALL: '/*',
  APP_ROUTES: {
    LOGIN: '/login',
    RESTORE_PASSWORD: '/restore-password',
    ADMIN: {
      ROOT: '/admin',
      NEW_PRAGMA_CANDIDATES: '/nuevospragmaticos',
      GREEN_LIST: '/greenlist',
      CANDIDATE_FORM: '/candidateForm',
      COMPLETE_CANDIDATE_FORM: '/completedCandidateForm',
    }
  },
  DASHBOARD_ROUTES: {
    WELCOME: '/welcome',
    ACTIVITIES: '/activities',
    CHANGE_PASSWORD: '/changepassword',
    HOME: {
      LABEL: 'home',
      ROUTE: '/home',
      CANDIDATE_SALARY_OPTIONS: '/candidate-salary-options',
      TEST_GRID: {
        LABEL: 'home-grit',
        ROUTE: '/test-grit',
      },
    },
    HOME_HIRING_LABEL: {
      LABEL: 'home-hiring',
      ROUTE: '/home-hiring',
    },
    PROFILE: {
      LABEL: 'profile',
      ROUTE: '/profile',
    },
    HELP: {
      LABEL: 'help',
      ROUTE: '/help',
    },
    HOME_HIRING: {
      ROOT: '/home-hiring',
      CONFIRM_DATA_UNDEFINED_TERM_COL: '/confirm-data-termino-indefinido-col',
      CONFIRM_DATA_LEARN_PRODUCTIVE_STAGE_COL: '/confirm-data-aprendizaje-etapa-productiva-col',
      CONFIRM_DATA_LEARN_LECTIVE_STAGE_COL: '/confirm-data-aprendizaje-etapa-lectiva-col',
      CONFIRM_DATA_PERMANENT_TERM_COL: '/confirm-data-termino-fijo-col',
      CONFIRM_DATA_FIXED_INTERN_COL: '/confirm-data-termino-fijo-practicante-col',
      UPLOAD_DOCUMENTS_LEARN_LECTIVE_STAGE_COL: '/upload-documents-aprendizaje-etapa-lectiva-col',
      UPLOAD_DOCUMENTS_LEARN_PRODUCTIVE_STAGE_COL: '/upload-documents-aprendizaje-etapa-productiva-col',
      UPLOAD_DOCUMENTS_UNDEFINED_TERM_COL: '/upload-documents-termino-indefinido-col',
      UPLOAD_DOCUMENTS_PERMANENT_TERM_COL: '/upload-documents-termino-fijo-col',
      UPLOAD_FIXED_INTERN_COL: '/upload-documents-termino-fijo-practicante-col',
      CONFIRM_DATA_UNDEFINED_TERM_PERU_PER: '/confirm-data-termino-indefinido-peru-per',
      CONFIRM_DATA_UNDEFINED_TERM_PER: '/confirm-data-termino-indefinido-per',
      CONFIRM_DATA_EMPLOYMENT_FORMATIVE_ALLIANCE_PRE_PER: '/confirm-data-convenio-formativo-laboral-pre-per',
      CONFIRM_DATA_EMPLOYMENT_FORMATIVE_ALLIANCE_PRO_PER: '/confirm-data-convenio-formativo-laboral-pro-per',
      CONFIRM_DATA_PERMANENT_TERM_PERU_PER: '/confirm-data-termino-fijo-peru-per',
      UPLOAD_DOCUMENTS_UNDEFINED_TERM_PERU_PER: '/upload-documents-termino-indefinido-peru-per',
      UPLOAD_DOCUMENTS_UNDEFINED_TERM_PER: '/upload-documents-termino-indefinido-per',
      UPLOAD_DOCUMENTS_PERMANENT_TERM_PERU_PER: '/upload-documents-termino-fijo-peru-per',
      UPLOAD_DOCUMENTS_EMPLOYMENT_FORMATIVE_ALLIANCE_PRE_PER: '/upload-documents-convenio-formativo-laboral-pre-per',
      UPLOAD_DOCUMENTS_EMPLOYMENT_FORMATIVE_ALLIANCE_PRO_PER: '/upload-documents-convenio-formativo-laboral-pro-per',
      WELCOME_KIT_PERMANENT_TERM_COL: '/welcome-kit-termino-fijo-col',
      KIT_FIXED_INTERN_COL: '/welcome-kit-termino-fijo-practicante-col',
      WELCOME_KIT_UNDEFINED_TERM_COL: '/welcome-kit-termino-indefinido-col',
      WELCOME_KIT_LEARN_PRODUCTIVE_STAGE_COL: '/welcome-kit-aprendizaje-etapa-productiva-col',
      WELCOME_KIT_LEARN_LECTIVE_STAGE_COL: '/welcome-kit-aprendizaje-etapa-lectiva-col',
      WELCOME_KIT_UNDEFINED_TERM_PER: '/welcome-kit-termino-indefinido-per',
      TELEWORKING_CONDITION: '/teleworking-conditions',
    }
  }
}