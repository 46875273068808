import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { AButton, AImage } from 'components/atoms';
import { LoadingPage } from 'components/molecules/LoadingPage/LoadingPage.component';
import { useTranslation } from 'hooks/useTranslation';
import { ConfirmNewPasswordPage } from 'pages/Authentication';
import {
  selectUi,
  setShowPassword,
  setUserInfo,
  resetLoginForm,
  resetLoginFormFull,
  selectLoginForm,
  setLoginForm,
  setRestorePasswordForm,
  setFormType,
  selectUserInfo,
} from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectTranslations } from 'store/translation/translation.slice';
import { errorIconParagraph, passwordHideIcon, passwordShowIcon, warningIcon } from 'utils/assets';
import { ILoginForm } from 'utils/interfaces';
import { loginFormSchema } from 'utils/schemas';

import './LoginForm.scss';

interface ICurrentUser {
  cognitoUser?: [];
  requiredAttributes?: [];
}

export const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userInfo = useAppSelector(selectUserInfo);
  const { email, password } = useAppSelector(selectLoginForm);
  const { showPasswordLogin } = useAppSelector(selectUi);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [needNewPassword, setNeedNewPassword] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>()
  const { translations, isLoading } = useAppSelector(selectTranslations);
  const { t } = useTranslation(translations, isLoading);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ILoginForm>({
    mode: 'onChange',
    defaultValues: { email, password },
    resolver: yupResolver(loginFormSchema),
  });

  useEffect(() => {
    dispatch(setFormType('restorePassword'));
  }, [])

  const handleChange = ({
    currentTarget: { name, value },
  }: React.FormEvent<HTMLInputElement>) => {
    dispatch(setLoginForm({ name, value }));
  };

  const onSubmit: SubmitHandler<ILoginForm> = async (values: ILoginForm) => {
    setLoading(true);

    try {
      const user = await Auth.signIn(values.email || '', values.password)
       .then(async (user) => {
            const  requiredAttributes  = user.challengeParam;
            const email = user.username;
            setCurrentUser({ cognitoUser: user, requiredAttributes })
            dispatch(resetLoginFormFull());
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              dispatch(setUserInfo({ ...userInfo, email, isLogged: false }));
              setNeedNewPassword(true)
            } else { 
              dispatch(setUserInfo({ ...userInfo, email, isLogged: true }));
              setNeedNewPassword(false)  
            }

            try {
              const session = (await Auth.currentSession()).getIdToken().getJwtToken();
              document.cookie = `auth_token=${session}; path=/; secure; SameSite=Strict; max-age=${60*60*24}`;
            } catch (sessionError) {
              console.error('Error obteniendo el token:', sessionError);
            }
        }
      )
    } catch (err: any) {
      if (err.name.includes('NotAuthorizedException')) {
        setError('El correo o la contraseña es incorrecto');
      } else {
        setError('No se pudo procesar su solicitud');
      }
    }

    dispatch(resetLoginForm());
    setLoading(false);
  };

  const handleForgotPasswordClick = () => {
    dispatch(setRestorePasswordForm({ name: 'email', value: '' }));
    navigate('/restore-password');
  };

  if (loading && !needNewPassword) {
    return <LoadingPage />;
  }

  if (needNewPassword) {
    return <ConfirmNewPasswordPage tittle='Cambia tu contraseña' isFirstPassword={true} user={currentUser}/>
  }

  return (
    <form
      className="login-form"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className="login-form__input-text">
        <input
          className={`login-form__input-text--element ${errors?.email ? 'login-form__input-text--element-error' : ''}`}
          id="email"
          type="email"
          value={email}
          placeholder="Correo electrónico"
          autoComplete="off"
          {...register('email', { onChange: handleChange })}
          tabIndex={1}
        />

        <p className='login-form__error-paragraph'>{errors?.email && <AImage className='login-form__error-icon' url={errorIconParagraph} alt="errorIconParagraph"/>}{errors?.email && `${errors.email.message}`}</p>

      </div>

      <div className="login-form__input-text">
        <AButton tag='button' type='button' className={!errors?.password ? 'login-form__input-text--button' : 'login-form__input-text--button-error'} handleClick={() => dispatch(setShowPassword('login'))}>
          <AImage
            role='presentation'
            className='login-form__input-text--icon'
            url={errors?.password ? warningIcon : showPasswordLogin ? passwordShowIcon : passwordHideIcon}
            alt=''
          />
        </AButton>
        <input
          data-testid="input"
          className={`login-form__input-text--element ${errors?.password ? 'login-form__input-text--element-error' : ''}`}
          id="password"
          type={showPasswordLogin ? 'text' : 'password'}
          value={password}
          placeholder='Contraseña'
          autoComplete="off"
           
          {...register('password', { onChange: handleChange })}
          tabIndex={2}
        />
        
        <p className='login-form__error-paragraph'>{errors?.password && <AImage className='login-form__error-icon' url={errorIconParagraph} alt="errorIconParagraph" />}{errors?.password && errors.password.message}</p>
        {error && <p className="login-form__error-paragraph"><AImage className='login-form__error-icon' url={errorIconParagraph} alt="errorIconParagraph" />{error}</p>}

      </div>

      <div className="login-form__link-text-container">

        <AButton
          tag='button'
          className='button-primary--white btn'
          disabled={!isValid}
          type='submit'
        >
          ¡Entrar!
        </AButton>
        <AButton 
          tag='button'
          className="login-form__link-text"
          type='button'
          handleClick={handleForgotPasswordClick}
          title='Olvidé mi contraseña'
        />

      </div>
    </form >
  );
};
