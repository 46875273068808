import { ATable } from 'components/atoms/ATable/ATable.components';
import './AdminPage.scss';
import Tabs from 'components/atoms/Tabs/Tabs.components';
import { useGetNewCandidatesQuery, useGetParameterQuery } from 'services/talento';
import { useEffect, useState } from 'react';
import {
  headersPending,
  headersCompleted,
  completedQuery,
  pendingQuery,
  labelTabs,
  arrayFirstElement,
  pendingText,
  minLengthFilter,
  greenListParameter,
} from 'utils/constants';
import { searchIcon, logoPragmaBanner, IconClose } from 'utils/assets';
import { EmptyString } from 'utils/helpers/dictionaries';
import { Auth } from 'aws-amplify';
import HeaderAdmin from 'components/organisms/AdminPanelForm/HeaderAdmin.component';
import Footer from 'components/molecules/Footer/Footer.component';

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState(pendingText);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isVisibleClose, setisVisibleClose] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const { data: pendingCandidates, refetch: refetchPending } =
    useGetNewCandidatesQuery({
      status: pendingQuery,
    });
  const { data: completedCandidates, refetch: refetchComplete } =
    useGetNewCandidatesQuery({
      status: completedQuery,
    });
  const { data: parameterName, refetch: refetchParameter } =
    useGetParameterQuery({
      parameterName: greenListParameter
    })
  const displayData =
    activeTab === pendingText ? pendingCandidates : completedCandidates;

  useEffect(() => {
    if (displayData) {
      setIsCompleted(!(activeTab === pendingText));
    }
  }, [displayData, activeTab]);

  labelTabs[arrayFirstElement].amount = pendingCandidates?.data.length;

  const filteredData = displayData?.data.filter((candidate: any) =>
    candidate.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('datos') || '{}');
  }, []);

  const [iniciales, setIniciales] = useState('');

  useEffect(() => {
    const obtenerIniciales = async () => {
      const user = await Auth.currentAuthenticatedUser();
      const correo = user.attributes.email;
      const partes = correo.split('@')[0].split('.');
      const iniciales = partes
        .map((part: string[]) => part[0].toUpperCase())
        .join('');
      setIniciales(iniciales);
    };
    obtenerIniciales();
  }, []);

  const HandleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  const handleSearchChange = (event: any) => {
    const value = event.target.value;

    const filteredValue = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g, EmptyString.emptyString);

    setInputValue(filteredValue);

    if (filteredValue.length >= minLengthFilter) {
      setSearchTerm(filteredValue);
    } else {
      setSearchTerm(EmptyString.emptyString);
    }
  };
  const HandleHola = () => {
    if (isVisibleClose) {
      setisVisibleClose(false);
    } else {
      setisVisibleClose(true);
    }
  };
  const handlerSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) { }
  };
  useEffect(() => {
    refetchPending();
    refetchComplete();
    refetchParameter();
  }, [AdminPage]);

  return (
    <>
    <div className='container__panel'>
        <HeaderAdmin />
        <div className="bgGrey">
          <div className="searchBar">
            <h1>Nuevos Pragmáticos</h1>
            <div className="searchBar__input">
              <input
                type="text"
                placeholder="Busca por nombre"
                onChange={handleSearchChange}
                value={inputValue}
              />
              <div className="searchIcon">
                <img src={searchIcon} alt="chevronLeft" />
              </div>
            </div>
          </div>

          <Tabs tabs={labelTabs} onChange={HandleTabChange} />
          <ATable
            isCompleted={isCompleted}
            data={filteredData || []}
            headers={
              activeTab === pendingText ? headersPending : headersCompleted
            }
            isDisableButon={false}
          />
        </div>
        <div className="footer-admin-container">
          <Footer isDark />
        </div>
      </div>
    </>
  );
};

export default AdminPage;
